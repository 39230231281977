@media only screen and (min-width: 320px) and (max-width: 768px) {
    header {
        padding: 0 15px;
    }
    header .lefticon img:nth-child(3) {
        width: 90px;
    }
    header .righticon ul li img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
    header .righticon ul li {
        margin: 0 10px;
    }
    header .righticon ul li:last-child {
        margin-right: 0;
    }
    .leftside,
    .lead .leadright {
        display: none;
    }
    .ant-drawer-body {
        padding: 0 !important;
    }
    .rightside,
    .leftside {
        float: none;
    }
    .rightside {
        width: 100%;
    }
    .sidemenu ul li {
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #EDEDED;
    }
    .sidemenu ul li .ant-menu-submenu-inline .ant-menu-submenu-title {
        padding: 7px 7px !important;
        cursor: pointer;
    }
    .lefticon img:nth-child(1) {
        display: none;
    }
    .lefticon img:nth-child(2) {
        display: block;
    }

    .leadsmain {
        padding: 15px 10px;
    }
    .LeadTable .MobViewNone {
        display: none;
    }
    .LeadTable .ant-table-tbody > tr > td {
       padding: 16px 6px !important; 
    }
    .Fillte {
        display: block;
        /* float: right; */
    }
    .LeadTable {
        margin: 20px 0 60px 0;
    }
    .cards .leadate {
        margin: 0;
    }
    .cards .leadate, 
    .cards .leadate1, 
    .cards .leadate2 {
        font-size: 13px;
    }
    .cards .card:nth-child(1) {
        margin: 0 0 30px 0;
    }
    .cards .cads {
        display: block;
    }
    .cads .card .usnam1 label:nth-child(1) {
        font-size: 10px;
        width: 55px;
    }
    .cads .card .usnam label:nth-child(1) {
        font-size: 10px;
        width: 84px;
    }
    .cads .card .usnam1 label:nth-child(2) {
        font-size: 10px;
    }
    .cads .card .usnam label:nth-child(2) {
        font-size: 10px;
    }
    .cards .card {
        padding: 10px;
    }
    .cards {
        padding: 15px 10px;
    }
    .mobbottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        display: block!important;
    }
    .LeadTable .Conat {
        font-size: 12px;
        font-weight: 600;
        color: #4A4A4A;
        line-height: 20px;
        margin: 0;
        padding: 0;
        display: block;
    }
    .LeadTable .Conat1 {
        font-size: 11px;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 12px;
        margin: 0;
        padding: 0;
        display: block;
    }
    .LeadTable .Conat12 {
        font-size: 11px;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 12px;
        margin: 0;
        padding: 0;
        display: inline-block;
    }
    .LeadTable .DropStatusimg {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        cursor: pointer;
    }
    #AddLeadTaskModal .addlead {
        height: 450px;
        overflow-y: auto;
    }
    #AddLeadTaskModal {
        max-width: 92%;
    }
    .addlead .ant-tabs-nav {
        width: 100%;
    }
    .addlead .ant-tabs-tab-active {
        height: 40px;
        width: auto;
        font-size: 12px;
        line-height: 19px;
        background-size: 14px 14px;
        background-position: 10px center;
        padding: 10px 10px 10px 30px !important;
    }
    .addlead .ant-tabs-tab {
        margin-bottom: 0 !important;
        height: 40px;
        width: auto;
        font-size: 12px;
        line-height: 19px;
        background-size: 14px 14px;
        background-position: 10px center;
        padding: 10px 10px 10px 30px !important;
    }
    .addlead .ant-tabs-nav-list {
        padding: 15px 10px;
    }
    .addlead .ant-tabs-tab {
        margin-right: 10px;
    }
    .addlead .ant-tabs-content-holder {
        background: #fff;
        padding: 10px 15px 30px 15px;
    }
    .addlead .customertab .bulk label:nth-child(1) {
        font-size: 14px;
        line-height: 30px;
    }
    .addlead .customertab .bulk label:nth-child(2) {
        height: 30px;
        font-size: 13px;
        line-height: 29px;
        padding: 0px 10px;
    }
    .addlead .ant-tabs-top > .ant-tabs-nav {
        margin-bottom: 0;
    }
    .addlead .customertab .mobileinput {
        margin-bottom: 10px;
    }
    #BulkUploadModal .addnote {
        width: 300px;
    }
    #BulkUploadModal {
        padding: 15px 30px;
    }
    #BulkUploadModal .fileupload {
        margin: 15px 0;
    }
    #BulkUploadModal .file-icon {
        padding: 15px 0;
        margin-bottom: 15px;
    }
    #BulkUploadModal .mainfileUpload {
        height: 230px;
    }
    #BulkUploadModal .addnote {
        height: 350px;
    }
    .LeadDetailMobile .Notes .ant-collapse {
        border: 0;
    }
    .LeadDetailMobile .leaDetLeft {
        width: 100%;
    }
    .LeadDetailMobile .leaDetRight {
        width: 100%;
    }
    .LeadDetailMobile .LeadDetail {
        display: block;
    }
    .LeadDetailMobile .NotesMain ul li,
    .LeadDetailMobile .HistoryMain ul li,
    .LeadDetailMobile .TaskMain ul li,
    .LeadDetailMobile .CallMain ul li {
        margin-bottom: 20px;
    }
    .LeadDetailMobile .NotesMain .anticon svg,
    .LeadDetailMobile .HistoryMain .anticon svg,
    .LeadDetailMobile .TaskMain .anticon svg,
    .LeadDetailMobile .CallMain .anticon svg {
        color: #fff;
        background: #07A287;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 4px 4px;
    }
    .LeadDetailMobile .NotesMain .pannel,
    .LeadDetailMobile .HistoryMain .pannel,
    .LeadDetailMobile .TaskMain .pannel,
    .LeadDetailMobile .CallMain .pannel {
        margin-left: 8px;
    }
    .LeadDetailMobile .Notes::after,
    .LeadDetailMobile .History::after,
    .LeadDetailMobile .TaskMain::after,
    .LeadDetailMobile .CallMain::after {
        position: absolute;
        content: "";
        border-right: 0;
        height: 100%;
        top: 45px;
        left: 45px;
    }
    .LeadDetailMobile .Notes,
    .LeadDetailMobile .History,
    .LeadDetailMobile .Taskk,
    .LeadDetailMobile .Calll {
        padding: 0;
    }
    .LeadDetailMobile .History .QuContent img,
    .LeadDetailMobile .Notes .QuContent img {
        margin: 0;
        width: 15px;
        height: 18px;
    }
    .LeadDetailMobile .History .QuContent span,
    .LeadDetailMobile .Notes .QuContent span {
        width: 35px;
        height: 36px;
        margin-right: 10px;
        border-radius: 50%;
        background: #EDEDED;
        text-align: center;
        line-height: 34px;
    }
    .LeadDetailMobile .pannel .DateTime .abou,
    .LeadDetailMobile .History .Quote .abou,
    .LeadDetailMobile .TaskMain .DateTime .abou,
    .LeadDetailMobile .TaskMain .Taskk .QuContent .dayago span,
    .LeadDetailMobile .CallMain .DateTime .abou,
    .LeadDetailMobile .CallMain .Calll .QuContent .abou {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
    }
    .LeadDetailMobile .pannel .DateTime .dayago,
    .LeadDetailMobile .TaskMain .pannel .DateTime .dayago,
    .LeadDetailMobile .TaskMain .Taskk .QuContent .dayago,
    .LeadDetailMobile .CallMain .pannel .DateTime .dayago,
    .LeadDetailMobile .CallMain .Calll .QuContent .dayago {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
    }
    .LeadDetailMobile .Notes .DateTime .dayago,
    .LeadDetailMobile .History .DateTime .dayago {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }
    .LeadDetailMobile .Notes .QuContent .Quote .dayago,
    .LeadDetailMobile .History .QuContent .Quote .dayago {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
    }
    .addlead .customertab .ant-select {
        margin-bottom: 10px;
    }
    .LeadDetailMobile .TaskMain .pannel .lefSide {
        display: flex;
        justify-content: space-between;
    }
    .LeadDetailMobile .EditPro {
        position: unset;
        margin: 0 0 10px 10px;
    }
    .LeadDetailMobile .planned,
    .LeadDetailMobile .Pastt, 
    .LeadDetailMobile .Completee {
        height: 26px;;
    }
    #AddNotesModal {
        width: 90%;
    }
    #AddCreateTaskModal {
        width: 90%;
        height: 400px;
    }
}
@media only screen and (min-width: 506px) and (max-width: 768px) {
    .cards .leadate {
        margin-right: 10px;
    }
    .cads .card .usnam1 label:nth-child(1) {
        font-size: 12px;
        width: 80px;
    }
    .cads .card .usnam label:nth-child(1) {
        font-size: 12px;
        width: 120px;
    }
    .cads .card .usnam1 label:nth-child(2) {
        font-size: 12px;
    }
    .cads .card .usnam label:nth-child(2) {
        font-size: 12px;
    }
    .cards .card {
        padding: 10px 15px;
    }
    .cards {
        padding: 15px 15px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
    .center {
        width: 275px;
        padding: 80px 25px 45px 25px;
    }
    .dropdown {
        width: 240px;
        margin-top: 10px;
        padding: 10px;
    }
    .UserInfo {
        width: 230px;
    }
    .UserInfo .usnam .usnam1 label:nth-child(1) {
        width: 60px;
    }
    .usnamebtn label {
        font-size: 10px;
        line-height: 28px;
        padding: 0 5px;
    }
    .usnamebtn label img {
        padding-right: 5px;
    }
    #BulkUploadModal {
        padding: 25px;
    }
    #BulkUploadModal .addnote {
        background-color: #ffffff;
        border-radius: 8px;
        height: 325px;
        width: 190px;
    }
    #BulkUploadModal .mainfileUpload {
        height: 190px;
    }
    .file-icon {
        padding: 15px 0 !important;
        margin-bottom: 10px !important;
    }
    #BulkUploadModal .fileupload {
        height: 28px;
        padding: 0 10px;
        font-size: 12px;
        margin: 12px 0;
    }
    #BulkUploadModal .editorbtn {
        float: none;
        display: flex;
    }
    #BulkUploadModal .editorbtn button:nth-child(1) {
        margin-right: 15px;
    }
    #BulkUploadModal .editorbtn button {
        padding: 10px 25px;
    }
    #BulkUploadModal label:nth-child(1) {
        font-size: 12px;
    }
    #BulkUploadModal label:nth-child(2) {
        font-size: 12px;
        padding: 4px;
    }
}