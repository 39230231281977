@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "./../../../../node_modules/bootstrap//dist//css//bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
.LeadDetail {
    background: #fff;
    width: 95%;
    margin: 15px auto 20px;
    padding: 0;
    border-radius: 8px;
    display: flex;
}
.leaDetLeft {
    width: 31%;
    background: #fff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.leaDetRight {
    width: 69%;
    background: #E9EDF3;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    /* padding: 10px; */
}
.leaDetLeft .LeUpper {
    border-bottom: 1px solid #E7E7E7;
    padding: 15px;
}
.leaDetLeft .LeUpper .leftimg img {
    cursor: pointer;
}
.leaDetLeft .LeUpper .leftimg img:nth-child(1) {
    margin-right: 10px;
}
.LeUpper .userimg {
    text-align: center;
    margin: 20px 0;
}
.LeUpper .userimg img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
}
.LeUpper .userimg h4 {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
}
.LeUpper .userimg h5 {
    color: #000;
    font-family: Lato;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
}
.LeUpper .userimg h5 img {
    margin: 0;
    width: 18px;
    height: 18px;
    float: right;
    cursor: pointer;
}
.LeUpper .step ul {
    list-style: none;
    margin: 0;
    display: flex;
    margin-bottom: 15px;
}
.LeUpper .step ul li.active {
    border: 1px solid #07A287;
    border-radius: 10px;
    background: #07A287;
}
.LeUpper .step ul li {
    width: 20%;
    height: 8px;
    border: 1px solid #DDE4ED;
    margin-right: 15px;
    border-radius: 10px;
    background: #DDE4ED;
}
.LeUpper .step ul li:last-child {
    margin-right: 0;
}
.leaDetLeft .LeDown {
    padding: 15px;
    margin-top: 10px;
}
.leaDetLeft .LeDown .abou {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    margin: 0;
}
.leaDetLeft .LeDown .dayago {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
}
.LeDown .rightUs {
    display: flex;
}
.LeDown .rightUs img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.LeDown input {
    width: 100%;
    padding: 5px 0;
    height: 30px;
    border-width: 0 0 2px;
    border-color: transparent;
}
.LeDown input:focus {
    /* border-bottom: 1px solid #2187FD; */
    /* border: 0; */
    outline: 0;
    border-color: #2187FD;
    color: #2187FD;
}
.LeDown .Up button:focus {
    outline: 0;
}
.LeDown .Up button {
    color: #2187FD;
    border: 1px solid #2187FD;
    background: transparent;
    font-size: 15px;
    line-height: 16px;
    padding: 6px;
    width: 100%;
    border-radius: 4px;
    font-weight: 500;
}
/* Right Side */
.leaDetRight .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #CAD3DF;
}
.leaDetRight img {
    margin: 0 5px;
}
.leaDetRight .ant-tabs-tab {
    margin-right: 0;
    padding: 15px 20px 15px 5px;
}
.leaDetRight .His {
    cursor: pointer;
}
.leaDetRight .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 600;
}
.leaDetRight .ant-tabs-tab-btn {
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    cursor: pointer;
}
/* History,Notes Tab CSS */
.HistoryMain,
.NotesMain,
.TaskMain,
.CallMain {
    margin: 0;
    list-style: none;
}
.History,
.Notes,
.Taskk,
.Calll {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    position: relative;
}
.HistoryMain ul li,
.NotesMain ul li,
.TaskMain ul li,
.CallMain ul li {
    margin-bottom: 50px;
}
.HistoryMain ul li:last-child .History::after,
.NotesMain ul li:last-child .Notes::after,
.CallMain ul li:last-child .Calll::after {
    position: absolute;
    content: "";
    border-right: 0;
    height: 100%;
    top: 45px;
    left: 45px;
}
.TaskMain ul li:last-child .Taskk::after {
    position: absolute;
    content: "";
    border-right: 0;
    height: 100%;
    top: 45px;
    left: 45px;
}
.History::after,
.Notes::after,
.Calll::after {
    position: absolute;
    content: "";
    border-right: 1.5px solid #979797;
    height: 100%;
    top: 45px;
    left: 45px;
}
.Taskk::after {
    position: absolute;
    content: "";
    border-right: 1.5px solid #979797;
    height: 100%;
    top: 45px;
    left: 45px;
}
.History .RiSide .rightUs,
.Notes .RiSide .rightUs,
.Taskk .RiSide .rightUs,
.Calll .RiSide .rightUs {
    display: flex;
}
.History .RiSide .rightUs img,
.Notes .RiSide .rightUs img,
.Taskk .RiSide .rightUs img,
.Calll .RiSide .rightUs img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.History .RiSide .rightUs .abou,
.Notes .RiSide .rightUs .abou,
.Taskk .RiSide .rightUs .abou,
.Calll .RiSide .rightUs .abou {
    color: #000000;
    font-family: Lato;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
    width: 85px;
}
.History .RiSide .rightUs .dayago,
.Notes .RiSide .rightUs .dayago,
.Taskk .RiSide .rightUs .dayago,
.Calll .RiSide .rightUs .dayago {
    color: #000000;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
    width: 85px;
}
.History .lefSide .rightUs,
.Notes .lefSide .rightUs,
.Taskk .lefSide .rightUs,
.Calll .lefSide .rightUs {
    display: flex;
}
.History .QuContent,
.Calll .QuContent {
    display: flex;
}
.Calll .QuContent .CallRecord {
    text-align: center;
}
.Notes .QuContent {
    display: flex;
    align-items: center;
}
.History .lefSide .rightUs .QuContent span,
.Notes .lefSide .rightUs .QuContent span {
    width: 35px;
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    line-height: 34px;
}
.History .lefSide .rightUs img,
.Notes .lefSide .rightUs img {
    margin: 0;
    width: 15px;
    height: 18px;
}
.History .lefSide .rightUs .dayago,
.Notes .lefSide .rightUs .dayago,
.Taskk .lefSide .rightUs .dayago,
.Calll .lefSide .rightUs .dayago {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
}
.History .lefSide .rightUs .abou,
.Notes .lefSide .rightUs .abou,
.Taskk .lefSide .rightUs .abou,
.Calll .lefSide .rightUs .abou {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
}
.History .lefSide .DateTime,
.Notes .lefSide .DateTime,
.Taskk .lefSide .DateTime {
    width: 100px;
}
.Calll .lefSide .DateTime {
    width: 70px;
}
.History .lefSide .Quote,
.Notes .lefSide .Quote,
.Taskk .lefSide .Quote {
    width: 260px;
}
.Calll .lefSide .Quote {
    width: 150px;
}
.History .lefSide .DateTime img,
.Notes .lefSide .DateTime img,
.Taskk .lefSide .DateTime img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.History .lefSide .DateTime .dayago,
.Notes .lefSide .DateTime .dayago,
.Taskk .lefSide .DateTime .dayago,
.Calll .lefSide .DateTime .dayago {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    width: auto;
}
.History .lefSide .DateTime .abou,
.Notes .lefSide .DateTime .abou,
.Taskk .lefSide .DateTime .abou,
.Calll .lefSide .DateTime .abou {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
    width: auto;
}
/* Email Tab CSS */
.EmailMain,
.MeetMain {
    padding: 10px 15px;
}
.EmailMain .ToEmai,
.MeetMain .ToEmai {
    display: flex;
}
.SmsMain {
    padding: 0 20px;
}
/* .MeetMain .ToEmai1 {
    display: block;
} */
.EmailMain .ToEmai label,
.MeetMain .ToEmai label {
    color: #4A4A4A;
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    padding: 0 0 4px 0;
    text-transform: capitalize;
}
.MeetMain .ToEmai1 label {
    color: #4A4A4A;
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    padding: 0;
    text-transform: capitalize;
    display: block;
}
.MeetMain .ToEmai1 .ant-picker-focused {
    box-shadow: none;
}
.EmailMain .ToEmai input,
.MeetMain .ToEmai input {
    width: 100%;
    padding: 0 8px 4px 10px;
    height: 30px;
    border-width: 0 0 2px;
    border-color: transparent;
    background: transparent;
}
.MeetMain .ToEmai1 input {
    width: 100%;
    padding: 0 8px 4px 10px;
    height: 30px;
    border-width: 0 0 2px;
    border-color: transparent;
    background: transparent;
}
.EmailMain .ToEmai input:focus,
.MeetMain .ToEmai input:focus {
    outline: 0;
    border-color: #2187FD;
    color: #2187FD;
}
.MeetMain .ToEmai1 input:focus {
    outline: 0;
    border-color: #2187FD;
    color: #2187FD;
}
.MeetMain .ToEmai1 .ant-picker,
.MeetMain .ToEmai1 .ant-select-selector,
.MeetMain .ToEmai1 .ant-select {
    width: 100%;
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 1px solid #4A4A4A !important;
    border-radius: 0 !important;
    padding: 0 !important;
}
.MeetMain .ToEmai1 .ant-picker input {
    padding: 0;
}
.EmailMain .cke_bottom,
.MeetMain .cke_bottom {
    display: none;
}
.EmailMain .cke_chrome,
.MeetMain .cke_chrome {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.EmailMain .cke_inner,
.MeetMain .cke_inner {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.EmailMain .cke_top,
.MeetMain .cke_top {
    background: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.EmailMain .SendBtn button,
.MeetMain .SendBtn button {
    background-color: #2187FD;
    border: 1px solid #2187FD;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 35px;
    cursor: pointer;
    text-transform: uppercase;
    position: absolute;
    right: 25px;
    bottom: 10px;
}
.planned,
.Pastt,
.Completee {
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
    border: 0;
    padding: 5px 15px;
    border-radius: 14px;
    margin-left: 15px;
}
.planned {
    background-color: #EDD200;
    border-radius: 14px;
}
.Pastt {
    background-color: #1F9E00;
    color: #fff;
}
.Completee {
    background-color: #1890ff;
    color: #fff;
}
.EditPro {
    /* float: right; */
    position: absolute;
    right: 45px;
    margin-right: 25px;
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
    border: 0;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: transparent;
    color: #1890ff;
    border: 1px solid #1890ff;
}
.AddTak {
    background: #1890ff;
    color: #fff;
    border: 1px solid #1890ff;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    padding: 6px;
    font-family: lato;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.SmsMain label {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
    display: block;
    margin-bottom: 10px;
}
.SmsMain textarea {
    display: block;
    width: 100%;
    border: 2px dotted #EDEDED;
    height: 130px;
    border-radius: 6px;
    padding: 5px;
    line-height: 15px;
}
.SmS img {
    width: 18px;
    height: 18px;
}
.SmsMain textarea:focus {
    outline: 0;
}
.SmsMain .cansen .sen {
    background: #1890ff;
    color: #fff;
}
.SmsMain .cansen .can {
    background: #fff;
    color: #1890ff;
    margin-right: 5px;
}
.SmsMain .cansen button {
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 10px 15px;
}
.SmsMain .cansen {
    float: right;
    margin: 10px 0;
}
/* Collapse */
.UserInfoo .ant-collapse {
    border: 0;
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    border-radius: 0;
}
/* .UserInfoo .ant-collapse-content {
    border-top: 0;
} */
.UserInfoo .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 16px 16px 16px;
}
.UserInfoo .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 0;
}
.UserInfoo .anticon svg {
    color: #fff;
    background: #07A287;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 4px 4px;
}
.UserInfoo .LeName:hover {
    background-color: #EDEDED;
}
.UserInfoo .Les {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lato';
    line-height: 14px;
    color: #07A287;
}
.UserInfoo .LeName label:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Lato';
    line-height: 20px;
    color: #4A4A4A;
    width: 115px;
}
.UserInfoo .LeName label:nth-child(2) {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Lato';
    line-height: 20px;
    color: #4A4A4A;
    margin-left: 5px;
}