@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "./../../../../node_modules/bootstrap/dist/css/bootstrap.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
.LoginBack {
    width: 100%;
    height: 100vh;
    background: #F2F4F7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.center {
   width: 400px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);;
    padding: 80px 45px 45px 45px;;
}
.center .email:focus {
    outline: 0;
}
.center .email {
    border-radius: 4px;
    width: 100%;
    height: 43px;
    padding: 10px;
    border: 0;
    background: #ECF2F4;
    color: #000;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
}
.center .email::placeholder {
    color: #9B9B9B;
    font-weight: 600;
    font-size: 14px;
}
.loginlogo {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.center .loginbtn {
    font-weight: 500;
    width: 100%;
    background: #2187FD;
    color: #fff;
    border: 0;
    border-radius: 4px;
    height: 43px;
    padding: 10px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    font-family: lato;
}
.center .loginbtn:focus {
    outline: 0;
}
.center .forpass {
    color: #2187FD;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    cursor: pointer;
}