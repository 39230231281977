@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./../../../../node_modules/bootstrap//dist//css//bootstrap.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
/* Lead Table */
.leadsmain {
  padding: 15px 20px;
  background-color: rgb(242, 244, 247);
}
.LeadTable {
  margin: 20px 0;
}
.LeadTable .Option {
  cursor: pointer;
}
.LeadTable .ant-table-tbody > tr > td:nth-child(2) {
  padding: 0;
}
.LeadTable .ant-table {
  background-color: #e5e9ed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.LeadTable .ant-table-tbody {
  background: #fff;
}
.LeadTable .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  /* padding: 18px 15px; */
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
.LeadTable .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
.LeadTable .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
.LeadTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eaeaea;
}
.LeadTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}
.LeadTable .Cate label,
.LeadTable .Cate1 label,
.LeadTable .Cate2 label,
.LeadTable .Cate3 label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Lato;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
.LeadTable .Cate1 span,
.LeadTable .Cate2 span {
  display: block;
  color: #9b9b9b;
  font-weight: 500;
}
.LeadTable .Cate2 {
  display: flex;
  align-items: center;
}
.LeadTable .Cate2 img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  cursor: pointer;
}
.LeadTable .Cate3 img {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: initial;
}
/* User Info Popover */
.ant-popover-inner {
  border-radius: 8px !important;
}
.ant-popover-inner-content {
  padding: 0 !important;
}
.UserInfo {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  width: 290px;
  height: auto;
  margin-top: 0;
  padding: 15px;
}
.UserInfo .usname {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.UserInfo .usname .usname1 label:nth-child(1) {
  display: block;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}
.UserInfo .usname .usname1 label:nth-child(2) {
  display: block;
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  line-height: 16px;
}
.UserInfo .usnam .usnam1 label:nth-child(1) {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 30px;
  width: 70px;
  text-align: left;
  font-weight: 600;
}
.UserInfo .usnam .usnam1 label:nth-child(2) {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 30px;
  margin-left: 10px;
  font-weight: 600;
}
.UserInfo .usnam {
  border-bottom: 1.5px solid #979797;
  border-top: 1.5px solid #979797;
  padding: 10px 0;
}
.usnamebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 10px 0;
}
.usnamebtn label:nth-child(1) {
  margin-right: 15px;
}
.usnamebtn label img {
  padding-right: 12px;
}
.usnamebtn label {
  border: 1px solid #2a93ff;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  color: #2a93ff;
  font-family: Lato;
  font-size: 12px;
  line-height: 28px;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;
}
/* Status Dropdown */
.ant-dropdown-menu {
  box-shadow: none !important;
  background: #fff !important;
  padding: 0 !important;
}
.dropdown {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  width: 290px;
  height: auto;
  margin-top: 15px;
  padding: 15px;
}
.dropdown .dropdownscroll {
  height: 145px;
  overflow-y: auto;
}
.dropdown .dropdownscroll::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.dropdown .dropdownscroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2d2d2;
  cursor: pointer;
}
.dropdown input:focus {
  outline: 0;
}
.dropdown .sear::placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 20px;
}
.dropdown .sear {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #595959;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
}
.dropdown img {
  position: absolute;
  right: 20px;
  top: 25px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.rad label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
}
.rad span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
/* Lead Header */
.lead {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.leftleft h4 {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
}
.leftleft h4 img {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.Fillte {
  display: none;
  margin: 5px 0 0 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.leftleft span {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: block;
}
.lead .leadright {
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
}
.leadright li:last-child {
  margin-right: 0;
}
.leadright li {
  margin: 0 10px;
}
.leadright li img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.leadright .sele .ant-select {
  width: auto;
  max-width: 110px;
}
.leadright .sele .ant-select-selection-item {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 34px !important;
}
.leadright .sele .ant-select-selector:focus {
  outline: 0;
}
.leadright
  .sele
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
.leadright .sele .ant-select-selector {
  box-shadow: none !important;
}
.leadright .sele .ant-select-selector {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  background: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
.leadright .sele .ant-select-arrow {
  right: 0 !important;
}
.leadright .sele .ant-select-selection-item,
.leadright .sele .ant-select-selection-placeholder {
  padding-right: 26px !important;
}
.filter {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}
.leadright .rad label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  cursor: pointer;
  position: relative;
}
.leadright .rad input {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.leadright .rad .design {
  width: 20px;
  height: 20px;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
}
.leadright .rad .design::before,
.leadright .rad .design::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  transform-origin: center center;
}
.leadright .rad .design:before {
  background: #1484ff;
  opacity: 0;
  transition: 0.3s;
}
.leadright .rad .design::after {
  /* background: red; */
  opacity: 0.4;
  transition: 0.6s;
}
.leadright .rad input:checked + .design {
  border: 2px solid #1484ff;
}
.leadright .rad input:checked + .design::before {
  opacity: 1;
  transform: scale(0.6);
}
.leadright .rad .text {
  font-weight: 400;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
}
/* Add Notes Modal */
#AddNotesModal .react-responsive-modal-closeButton {
  display: none;
}
#AddNotesModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#AddNotesModal .addnote {
  background-color: #ffffff;
  border-radius: 8px;
  height: auto;
  width: 700px;
}
#AddNotesModal label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}
#AddNotesModal .addnote .cke_bottom {
  display: none;
}
#AddNotesModal .addnote .cke_top {
  background: #fff;
}
#AddNotesModal .editorbtn {
  float: right;
}
#AddNotesModal .editorbtn button:focus {
  outline: 0;
}
#AddNotesModal .editorbtn button {
  border-radius: 4px;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 40px;
  text-transform: capitalize;
  margin-top: 20px;
}
#AddNotesModal .editorbtn button:nth-child(1) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  margin-right: 15px;
}
#AddNotesModal .editorbtn button:nth-child(2) {
  background-color: #bcbcbc;
  border: 1px solid #bcbcbc;
}
/* Card View */
.cards {
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 15px 20px;
  margin: 20px 0;
}
.cards .leadid {
  background-color: #e5e5e5;
  border-radius: 6px;
  color: #0091ff;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.cards .leadate,
.cards .leadate1,
.cards .leadate2 {
  color: #000000;
  font-family: Lato;
  font-size: 15px;
  line-height: 19px;
  opacity: 0.5;
  font-weight: 600;
}
.cards .leadate {
  margin-right: 90px;
}
.cards .leadate2 {
  float: right;
}
.cards .leada {
  border-bottom: 1.5px solid #ededed;
  padding: 20px 0;
}
.cards .cads {
  display: flex;
  margin: 20px 0 10px;
}
.cards .card {
  flex: 1;
  border: 0;
  padding: 10px 15px;
  background-color: #f6f6f6 !important;
  border-radius: 8px !important;
}
.cards .card:nth-child(1) {
  margin-right: 55px;
}
.cads .card .custinfo {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.cads .card .usnam1 label:nth-child(1) {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 30px;
  width: 70px;
  text-align: left;
  font-weight: 600;
}
.cads .card .usnam1 label:nth-child(2) {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 30px;
  margin-left: 10px;
  font-weight: 600;
}
.cads .card .usnam label:nth-child(1) {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  width: 135px;
  text-align: left;
  font-weight: 600;
}
.cads .card .usnam label:nth-child(2) {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  font-weight: 600;
}
/* Option Dropdown */
.Opdropdown {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  width: auto;
  height: auto;
  margin-top: 5px;
  padding: 5px 5px 5px 15px;
}
.Opdropdown .dropdownscroll {
  height: auto;
  max-height: 266px;
  overflow-y: auto;
}
.Opdropdown .dropdownscroll::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.Opdropdown .dropdownscroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2d2d2;
  cursor: pointer;
}
.rad1 {
  margin-right: 10px;
}
.rad1 label {
  margin: 5px 0;
}
.rad1 span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
/* Add Lead Task Modal */
#AddLeadTaskModal .react-responsive-modal-closeButton {
  display: none;
}
#AddLeadTaskModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 75%;
}
#AddLeadTaskModal .addlead {
  height: 550px;
  overflow-y: auto;
}
#AddLeadTaskModal .addlead::-webkit-scrollbar,
#AddCreateTaskModal .addtas::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
#AddLeadTaskModal .addlead::-webkit-scrollbar-thumb,
#AddCreateTaskModal .addtas::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2d2d2;
  cursor: pointer;
}
.addlead .ant-tabs-nav {
  width: 280px;
  background: #e3efff;
}
.addlead .ant-tabs-content-holder {
  background: #fff;
  padding: 30px 20px;
}
.addlead .ant-tabs-tab {
  margin-bottom: 0 !important;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  height: 42px;
  background-image: url("./../../images/circle.png");
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 10px 10px 10px 45px !important;
}
.addlead .ant-tabs-nav-operations {
  display: none !important;
}
.addlead .ant-tabs-tab-active {
  background-color: #ffffff;
  border-radius: 4px;
  height: 42px;
  width: 240px;
  color: #0067de;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600 !important;
  background-image: url("./../../images/CheckBlue.png");
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 10px 10px 10px 45px !important;
}
.addlead .ant-tabs-tab-btn {
  font-weight: 600 !important;
}
.addlead .ant-tabs-nav-list {
  padding: 25px;
}
.addlead .ant-tabs-ink-bar {
  display: none;
}
.addlead .customertab .bulk {
  display: flex;
  justify-content: space-between;
}
.addlead .customertab .bulk label:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
}
.addlead .customertab .bulk label:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 35px;
  cursor: pointer;
  padding: 0px 18px;
  text-transform: capitalize;
}
.addlead .customertab .mobile {
  display: block;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addlead .customertab input:focus {
  outline: 0;
}
.addlead .customertab .mobileinput {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #595959;
  width: 100%;
  padding: 5px 10px 5px 0px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addlead .customertab .ant-picker:focus {
  outline: 0;
}
.addlead .customertab .casane {
  display: flex;
}
.addlead .customertab .casane button:focus {
  outline: 0;
}
.addlead .customertab .casane button {
  width: 100%;
  border: 0;
}
.addlead .customertab .casane button:nth-child(1),
.addlead .customertab .casane button:nth-child(2) {
  margin-right: 5px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  background: #fff;
  color: #4a4a4a;
}
.addlead .customertab .casane button:nth-child(3) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
}
.addlead .customertab .ant-select {
  width: 100%;
}
.addlead .customertab .ant-select:hover {
  border-bottom: 1px solid #595959;
}
.addlead .customertab .ant-select {
  border-bottom: 1px solid #595959;
}
.addlead .ant-select-selector {
  border: 0 !important;
  border-radius: 0 !important;
  /* border-bottom: 1px solid #595959 !important; */
  width: 100% !important;
  padding: 5px 10px 5px 0px !important;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addlead .ant-select-arrow {
  color: #000 !important;
}
.addlead
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.addlead .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
}
.addlead .ant-select-single .ant-select-selector .ant-select-selection-item,
.addlead
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 23px !important;
}
#AddLeadTaskModal .addlead .cke_top {
  background: #fff;
}
#AddLeadTaskModal .addlead .cke_bottom {
  display: none;
}
/* Bulk Upload Modal */
#BulkUploadModal .react-responsive-modal-closeButton,
#AddCreateTaskModal .react-responsive-modal-closeButton {
  display: none;
}
#BulkUploadModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 40px;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#BulkUploadModal .addnote {
  background-color: #ffffff;
  border-radius: 8px;
  height: 400px;
  width: 600px;
}
#BulkUploadModal .bul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#BulkUploadModal label:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}
#BulkUploadModal label:nth-child(2) {
  font-weight: 600;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}
#BulkUploadModal .drop {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#BulkUploadModal .down {
  display: block;
  color: #2187fd;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#BulkUploadModal .mainfileUpload div:focus {
  outline: 0;
}
#BulkUploadModal .mainfileUpload {
  width: 100%;
  height: 300px;
  border: 3px dotted #1484ff;
  border-radius: 8px;
  margin: 15px 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9edf3;
}
#BulkUploadModal .file-icon img {
  width: 30px;
  height: 30px;
}
#BulkUploadModal .file-icon {
  background: #dde3ec;
  padding: 25px 0;
  border-radius: 6px;
  margin-bottom: 20px;
}
#BulkUploadModal .fileupload {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  padding: 0 10px;
  cursor: pointer;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin: 20px 0;
}
/* #BulkUploadModal .editor{
    width: 100%;
    height: 300px;
    border: 1px solid #D8DEE8;
    border-radius: 8px;
    margin: 15px 0;
} */
#BulkUploadModal .editorbtn {
  float: right;
}
#BulkUploadModal .editorbtn button:focus {
  outline: 0;
}
#BulkUploadModal .editorbtn button {
  border-radius: 4px;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 40px;
  text-transform: capitalize;
}
#BulkUploadModal .editorbtn button:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
}
#BulkUploadModal .editorbtn button:nth-child(1) {
  background-color: #bcbcbc;
  border: 1px solid #bcbcbc;
  margin-right: 15px;
}
/* Add Create Task Modal */
#AddCreateTaskModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#AddCreateTaskModal .addtas {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 700px;
}
.addtas .customertab .bulk {
  display: flex;
  justify-content: space-between;
}
.addtas .customertab .bulk label:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
}
.addtas .customertab .bulk label:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 35px;
  cursor: pointer;
  padding: 0px 18px;
  text-transform: capitalize;
}
.addtas .customertab .mobile {
  display: block;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addtas .customertab input:focus {
  outline: 0;
}
.addtas .customertab .mobileinput {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #595959;
  width: 100%;
  padding: 5px 10px 5px 0px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addtas .customertab .ant-picker:focus {
  outline: 0;
}
.addtas .customertab .casane {
  display: flex;
}
.addtas .customertab .casane button:focus {
  outline: 0;
}
.addtas .customertab .casane button {
  width: 100%;
  border: 0;
}
.addtas .customertab .casane button:nth-child(2) {
  margin-right: 5px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  background-color: #bcbcbc;
  border-radius: 4px;
  color: #fff;
}
.addtas .customertab .casane button:nth-child(1) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  margin-right: 10px;
}
.addtas .customertab .ant-select {
  width: 100%;
}
.addtas .customertab .ant-select:hover {
  border-bottom: 1px solid #595959;
}
.addtas .customertab .ant-select {
  border-bottom: 1px solid #595959;
}
.addtas .ant-select-selector {
  border: 0 !important;
  border-radius: 0 !important;
  /* border-bottom: 1px solid #595959 !important; */
  width: 100% !important;
  padding: 5px 10px 5px 0px !important;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addtas .ant-select-arrow {
  color: #000 !important;
}
.addtas
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.addtas .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
}
.addtas .ant-select-single .ant-select-selector .ant-select-selection-item,
.addtas
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 23px !important;
}
#AddCreateTaskModal .addtas .Sear {
  display: flex;
}
#AddCreateTaskModal .addtas .provide {
  background-color: #2a93ff;
  border-radius: 4px;
  color: #fff;
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  padding: 10px 15px 10px 10px;
  margin-top: 5px;
  cursor: pointer;
}
#AddCreateTaskModal .addtas .provide img {
  margin-right: 10px;
}
#AddCreateTaskModal .addtas .cke_bottom {
  display: none;
}
#AddCreateTaskModal .addtas .cke_top {
  background: #fff;
}
#AddCreateTaskModal .addtas .acti .ant-radio-button-wrapper {
  border: 1px solid #2a93ff;
  color: #2a93ff;
  font-family: Lato;
  font-size: 12px;
  line-height: 26px;
  height: auto;
  padding: 2px 10px;
}
#AddCreateTaskModal
  .addtas
  .acti
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
#AddCreateTaskModal
  .addtas
  .acti
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  padding: 2px 10px;
  color: #fff;
  background: #1890ff;
}
#AddCreateTaskModal .addtas .acti img {
  padding: 0 7px 2px 0;
}
.isvisibalePophover {
  display: none;
}
.isvisibalePophover{
    display: none;
}
.expandown img {
    transform: rotate(270deg);
    width: 16px;
    height: 16px;;
}
.expandown1 img {
    transform: rotate(90deg);
    width: 16px;
    height: 16px;
}
/* Mobile View Filter */
.mobbottom {
  display: block!important;
}
.main {
  padding: 10px;
  /* background-image: linear-gradient(180deg,#3c5579,#242f3f); */
  background: #e5e9ed;
  border: 1px solid #e5e9ed;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  display: flex;
  justify-content: center;
}
.item1 {
  text-align: center;
  margin: auto;
}
.background {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: auto;
}
.background img {
  width: 15px;
  padding: 7px 0 0;
}
.mobbottom label {
  font-size: 10px;
}