@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
.LoginBack {
    width: 100%;
    height: 100vh;
    background: #F2F4F7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.center {
   width: 400px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);;
    padding: 80px 45px 45px 45px;;
}
.center .email:focus {
    outline: 0;
}
.center .email {
    border-radius: 4px;
    width: 100%;
    height: 43px;
    padding: 10px;
    border: 0;
    background: #ECF2F4;
    color: #000;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
}
.center .email::-webkit-input-placeholder {
    color: #9B9B9B;
    font-weight: 600;
    font-size: 14px;
}
.center .email:-ms-input-placeholder {
    color: #9B9B9B;
    font-weight: 600;
    font-size: 14px;
}
.center .email::placeholder {
    color: #9B9B9B;
    font-weight: 600;
    font-size: 14px;
}
.loginlogo {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}
.center .loginbtn {
    font-weight: 500;
    width: 100%;
    background: #2187FD;
    color: #fff;
    border: 0;
    border-radius: 4px;
    height: 43px;
    padding: 10px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    font-family: lato;
}
.center .loginbtn:focus {
    outline: 0;
}
.center .forpass {
    color: #2187FD;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
/* Lead Table */
.leadsmain {
  padding: 15px 20px;
  background-color: rgb(242, 244, 247);
}
.LeadTable {
  margin: 20px 0;
}
.LeadTable .Option {
  cursor: pointer;
}
.LeadTable .ant-table-tbody > tr > td:nth-child(2) {
  padding: 0;
}
.LeadTable .ant-table {
  background-color: #e5e9ed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.LeadTable .ant-table-tbody {
  background: #fff;
}
.LeadTable .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  /* padding: 18px 15px; */
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
.LeadTable .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px;
}
.LeadTable .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
.LeadTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eaeaea;
}
.LeadTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}
.LeadTable .Cate label,
.LeadTable .Cate1 label,
.LeadTable .Cate2 label,
.LeadTable .Cate3 label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Lato;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
.LeadTable .Cate1 span,
.LeadTable .Cate2 span {
  display: block;
  color: #9b9b9b;
  font-weight: 500;
}
.LeadTable .Cate2 {
  display: flex;
  align-items: center;
}
.LeadTable .Cate2 img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  cursor: pointer;
}
.LeadTable .Cate3 img {
  width: 12px;
  height: 12px;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: baseline;
  vertical-align: initial;
}
/* User Info Popover */
.ant-popover-inner {
  border-radius: 8px !important;
}
.ant-popover-inner-content {
  padding: 0 !important;
}
.UserInfo {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  width: 290px;
  height: auto;
  margin-top: 0;
  padding: 15px;
}
.UserInfo .usname {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}
.UserInfo .usname .usname1 label:nth-child(1) {
  display: block;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}
.UserInfo .usname .usname1 label:nth-child(2) {
  display: block;
  color: #9b9b9b;
  font-family: Lato;
  font-size: 13px;
  line-height: 16px;
}
.UserInfo .usnam .usnam1 label:nth-child(1) {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 30px;
  width: 70px;
  text-align: left;
  font-weight: 600;
}
.UserInfo .usnam .usnam1 label:nth-child(2) {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 30px;
  margin-left: 10px;
  font-weight: 600;
}
.UserInfo .usnam {
  border-bottom: 1.5px solid #979797;
  border-top: 1.5px solid #979797;
  padding: 10px 0;
}
.usnamebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 10px 0;
}
.usnamebtn label:nth-child(1) {
  margin-right: 15px;
}
.usnamebtn label img {
  padding-right: 12px;
}
.usnamebtn label {
  border: 1px solid #2a93ff;
  border-radius: 4px;
  width: 100%;
  height: 32px;
  color: #2a93ff;
  font-family: Lato;
  font-size: 12px;
  line-height: 28px;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;
}
/* Status Dropdown */
.ant-dropdown-menu {
  box-shadow: none !important;
  background: #fff !important;
  padding: 0 !important;
}
.dropdown {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  width: 290px;
  height: auto;
  margin-top: 15px;
  padding: 15px;
}
.dropdown .dropdownscroll {
  height: 145px;
  overflow-y: auto;
}
.dropdown .dropdownscroll::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.dropdown .dropdownscroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2d2d2;
  cursor: pointer;
}
.dropdown input:focus {
  outline: 0;
}
.dropdown .sear::-webkit-input-placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 20px;
}
.dropdown .sear:-ms-input-placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 20px;
}
.dropdown .sear::placeholder {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 20px;
}
.dropdown .sear {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #595959;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  padding: 5px;
}
.dropdown img {
  position: absolute;
  right: 20px;
  top: 25px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.rad label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 10px 0;
  cursor: pointer;
  position: relative;
}
.rad span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
/* Lead Header */
.lead {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.leftleft h4 {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
}
.leftleft h4 img {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.Fillte {
  display: none;
  margin: 5px 0 0 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.leftleft span {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: block;
}
.lead .leadright {
  display: flex;
  align-items: center;
  margin: 0;
  list-style: none;
}
.leadright li:last-child {
  margin-right: 0;
}
.leadright li {
  margin: 0 10px;
}
.leadright li img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.leadright .sele .ant-select {
  width: auto;
  max-width: 110px;
}
.leadright .sele .ant-select-selection-item {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 34px !important;
}
.leadright .sele .ant-select-selector:focus {
  outline: 0;
}
.leadright
  .sele
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
.leadright .sele .ant-select-selector {
  box-shadow: none !important;
}
.leadright .sele .ant-select-selector {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  background: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding: 0 !important;
}
.leadright .sele .ant-select-arrow {
  right: 0 !important;
}
.leadright .sele .ant-select-selection-item,
.leadright .sele .ant-select-selection-placeholder {
  padding-right: 26px !important;
}
.filter {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}
.leadright .rad label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  cursor: pointer;
  position: relative;
}
.leadright .rad input {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.leadright .rad .design {
  width: 20px;
  height: 20px;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
}
.leadright .rad .design::before,
.leadright .rad .design::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  transform: scale(0);
  transform-origin: center center;
}
.leadright .rad .design:before {
  background: #1484ff;
  opacity: 0;
  transition: 0.3s;
}
.leadright .rad .design::after {
  /* background: red; */
  opacity: 0.4;
  transition: 0.6s;
}
.leadright .rad input:checked + .design {
  border: 2px solid #1484ff;
}
.leadright .rad input:checked + .design::before {
  opacity: 1;
  transform: scale(0.6);
}
.leadright .rad .text {
  font-weight: 400;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
}
/* Add Notes Modal */
#AddNotesModal .react-responsive-modal-closeButton {
  display: none;
}
#AddNotesModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#AddNotesModal .addnote {
  background-color: #ffffff;
  border-radius: 8px;
  height: auto;
  width: 700px;
}
#AddNotesModal label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 20px;
}
#AddNotesModal .addnote .cke_bottom {
  display: none;
}
#AddNotesModal .addnote .cke_top {
  background: #fff;
}
#AddNotesModal .editorbtn {
  float: right;
}
#AddNotesModal .editorbtn button:focus {
  outline: 0;
}
#AddNotesModal .editorbtn button {
  border-radius: 4px;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 40px;
  text-transform: capitalize;
  margin-top: 20px;
}
#AddNotesModal .editorbtn button:nth-child(1) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  margin-right: 15px;
}
#AddNotesModal .editorbtn button:nth-child(2) {
  background-color: #bcbcbc;
  border: 1px solid #bcbcbc;
}
/* Card View */
.cards {
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 15px 20px;
  margin: 20px 0;
}
.cards .leadid {
  background-color: #e5e5e5;
  border-radius: 6px;
  color: #0091ff;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.cards .leadate,
.cards .leadate1,
.cards .leadate2 {
  color: #000000;
  font-family: Lato;
  font-size: 15px;
  line-height: 19px;
  opacity: 0.5;
  font-weight: 600;
}
.cards .leadate {
  margin-right: 90px;
}
.cards .leadate2 {
  float: right;
}
.cards .leada {
  border-bottom: 1.5px solid #ededed;
  padding: 20px 0;
}
.cards .cads {
  display: flex;
  margin: 20px 0 10px;
}
.cards .card {
  flex: 1 1;
  border: 0;
  padding: 10px 15px;
  background-color: #f6f6f6 !important;
  border-radius: 8px !important;
}
.cards .card:nth-child(1) {
  margin-right: 55px;
}
.cads .card .custinfo {
  color: #000000;
  font-family: Lato;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.cads .card .usnam1 label:nth-child(1) {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 30px;
  width: 70px;
  text-align: left;
  font-weight: 600;
}
.cads .card .usnam1 label:nth-child(2) {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 30px;
  margin-left: 10px;
  font-weight: 600;
}
.cads .card .usnam label:nth-child(1) {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  width: 135px;
  text-align: left;
  font-weight: 600;
}
.cads .card .usnam label:nth-child(2) {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  font-weight: 600;
}
/* Option Dropdown */
.Opdropdown {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  width: auto;
  height: auto;
  margin-top: 5px;
  padding: 5px 5px 5px 15px;
}
.Opdropdown .dropdownscroll {
  height: auto;
  max-height: 266px;
  overflow-y: auto;
}
.Opdropdown .dropdownscroll::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.Opdropdown .dropdownscroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2d2d2;
  cursor: pointer;
}
.rad1 {
  margin-right: 10px;
}
.rad1 label {
  margin: 5px 0;
}
.rad1 span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
/* Add Lead Task Modal */
#AddLeadTaskModal .react-responsive-modal-closeButton {
  display: none;
}
#AddLeadTaskModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 75%;
}
#AddLeadTaskModal .addlead {
  height: 550px;
  overflow-y: auto;
}
#AddLeadTaskModal .addlead::-webkit-scrollbar,
#AddCreateTaskModal .addtas::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
#AddLeadTaskModal .addlead::-webkit-scrollbar-thumb,
#AddCreateTaskModal .addtas::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #d2d2d2;
  cursor: pointer;
}
.addlead .ant-tabs-nav {
  width: 280px;
  background: #e3efff;
}
.addlead .ant-tabs-content-holder {
  background: #fff;
  padding: 30px 20px;
}
.addlead .ant-tabs-tab {
  margin-bottom: 0 !important;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  height: 42px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQflAhQMHBzUiktPAAABzUlEQVRIx6XVPU9UQRgF4Gc3ETAuCSQqRIIu29JoTyOlNlTGhvBT3ESJaC1R/G7sCAmFdiIhuxor5QeIWJqlMLl3F7ZhLJYLxL3sx/VMM/Pe+56ZOTPvmZw0DLtt1nVFI/jjp+82vBfrAeOWxcJRq6kd92NPjHVOHlQWCWKr5hUNHkWL5q2KBZF7R9EUXLQpqFsykvq9oKwh+Jy+jmm/BFsmOq5xUkWwa7p99h+Clwa6ajTglWDX5dPBczYFb3rRGDwXVE9rURZs9TD7idgVwWIyHBdpdNl7uxb7mqZag2XBw77S4ZHgGQyLRWccXCeMikUK3BWs9p0Oa4I7ebNYz0Swjlm+CokYfaIk+ELN4dm3uyOGHPpNUy1TOuw5yCOXmSDHf28hb0fOlUwEE3J28rYxk4lgBtt5G5jLRDCHjxTE4kxXuS5ygVYxLfVN8DgpJsZEGib7Sr9m34FiMiwLKn0ZSlXw4CTQsrS3PRO8+NfSElN953wPs79uN1USW6900eKqarqtt1bxSdC04lJq8qglDUH17Adu0KKmoG7NgpIhMKRkwZq64MD9bmKXrJx6XPfsHfcjT08OLkF6KRfcctMNU8fP+zcbPqi3//oXKmOj0jp8oX0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDItMjBUMTI6Mjg6MjgrMDA6MDB8HnAWAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTAyLTIwVDEyOjI4OjI4KzAwOjAwDUPIqgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 10px 10px 10px 45px !important;
}
.addlead .ant-tabs-nav-operations {
  display: none !important;
}
.addlead .ant-tabs-tab-active {
  background-color: #ffffff;
  border-radius: 4px;
  height: 42px;
  width: 240px;
  color: #0067de;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600 !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABGlBMVEUAAAAkgP8iiPwhh/0hh/whh/0hh/0hiP0ih/wnif8hif8gh/0hh/0hh/0hiP0iiv8agP8hh/0hh/0hh/whh/0gh/0hh/0hiP0ggP8hh/0hh/4ihvwXi/8hh/4hh/wfhf8fh/8hh/wjiP8jhv8ih/0hiP8hhv1AgP8hh/0hh/0ihv0hh/0ghv0hh/0zmf8hhvwhh/0iiP8ih/0iif8Aqv8hh/4fh/wihvwhh/0giPwih/wgh/whh/0rgP8hh/0iiPwhh/whh/0ghv0gh/0hh/0fhf8hh/0cjv8hh/0hiP0hh/0hh/wihv0hhvwhiPwhhv8ihv8kiv8ih/0hhv0kkv8giv8hh/0hh/0hh/0chP8hhf8hh/4hh/0AAAAHcC3yAAAAXHRSTlMADlqbyur6mlkNJ5329ZwlCoz7waSO94sI07BSC7HIGTHHOjvkL5QEleNyc5aCBcn0PJc0A7NRTPlYU1f4DJlLwuulpuky8hLOjdjDmFRWPSYjiHQHGOKR5hsush8U8t8AAAABYktHRACIBR1IAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QIUDCAsgaACHAAAAZ1JREFUOMttU/kjAmEQHbWVaCXpoNotlZB0KBYdJEIH5eb9/3+H2V3ttsf88s3Me/O9me8gMmzF4xV8fr9PCKwGyWlr6yEYJm6EbfBmZIvzoeh2LLYdjbPrTySX8fAO53ZTaT1KpzIcSrKJZ31Abm+5Ii8BhaJRz/h+yapZOgAO//fY5P2PnF2XWUXvI8L1LmPRMVDR5ttCruRGOKmipoqsA3vkanWgwecnYtcBnXqa6tKCGCQPcGbHzxVcqOslcEVehNJ2vI12VnU6cXRJQNQF7+nuNSS6QV8Pmk6cbjEgBRHNv7uXhnacj6htELjZh6ENp0cmLCSafZXRs+CaRHXRpMp4suI0Qo4CiKdNhhXnMce0CqTIYFhwmgBTCorILBLNi6zlRFqodbTLyrtf1jPg5eXFD+nE9boFKDPVSQAHboQGMNecJD/QshN/5Yf8prvvH0DXplL6BL6+F1GxAFTrlv4EftQ/ZiyzCn4nnf/zmbQ4zMnLFclKjXPxUT8W64+0rzdPWjVJbojm5615Zy5jBa/G0kBRBtJ42jGzf4FAXRs53P1OAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTAyLTIwVDEyOjMyOjQ0KzAwOjAwq31c7AAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMi0yMFQxMjozMjo0NCswMDowMNog5FAAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 10px 10px 10px 45px !important;
}
.addlead .ant-tabs-tab-btn {
  font-weight: 600 !important;
}
.addlead .ant-tabs-nav-list {
  padding: 25px;
}
.addlead .ant-tabs-ink-bar {
  display: none;
}
.addlead .customertab .bulk {
  display: flex;
  justify-content: space-between;
}
.addlead .customertab .bulk label:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
}
.addlead .customertab .bulk label:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 35px;
  cursor: pointer;
  padding: 0px 18px;
  text-transform: capitalize;
}
.addlead .customertab .mobile {
  display: block;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addlead .customertab input:focus {
  outline: 0;
}
.addlead .customertab .mobileinput {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #595959;
  width: 100%;
  padding: 5px 10px 5px 0px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addlead .customertab .ant-picker:focus {
  outline: 0;
}
.addlead .customertab .casane {
  display: flex;
}
.addlead .customertab .casane button:focus {
  outline: 0;
}
.addlead .customertab .casane button {
  width: 100%;
  border: 0;
}
.addlead .customertab .casane button:nth-child(1),
.addlead .customertab .casane button:nth-child(2) {
  margin-right: 5px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  background: #fff;
  color: #4a4a4a;
}
.addlead .customertab .casane button:nth-child(3) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
}
.addlead .customertab .ant-select {
  width: 100%;
}
.addlead .customertab .ant-select:hover {
  border-bottom: 1px solid #595959;
}
.addlead .customertab .ant-select {
  border-bottom: 1px solid #595959;
}
.addlead .ant-select-selector {
  border: 0 !important;
  border-radius: 0 !important;
  /* border-bottom: 1px solid #595959 !important; */
  width: 100% !important;
  padding: 5px 10px 5px 0px !important;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addlead .ant-select-arrow {
  color: #000 !important;
}
.addlead
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.addlead .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
}
.addlead .ant-select-single .ant-select-selector .ant-select-selection-item,
.addlead
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 23px !important;
}
#AddLeadTaskModal .addlead .cke_top {
  background: #fff;
}
#AddLeadTaskModal .addlead .cke_bottom {
  display: none;
}
/* Bulk Upload Modal */
#BulkUploadModal .react-responsive-modal-closeButton,
#AddCreateTaskModal .react-responsive-modal-closeButton {
  display: none;
}
#BulkUploadModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 40px;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#BulkUploadModal .addnote {
  background-color: #ffffff;
  border-radius: 8px;
  height: 400px;
  width: 600px;
}
#BulkUploadModal .bul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#BulkUploadModal label:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
}
#BulkUploadModal label:nth-child(2) {
  font-weight: 600;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
}
#BulkUploadModal .drop {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#BulkUploadModal .down {
  display: block;
  color: #2187fd;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#BulkUploadModal .mainfileUpload div:focus {
  outline: 0;
}
#BulkUploadModal .mainfileUpload {
  width: 100%;
  height: 300px;
  border: 3px dotted #1484ff;
  border-radius: 8px;
  margin: 15px 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9edf3;
}
#BulkUploadModal .file-icon img {
  width: 30px;
  height: 30px;
}
#BulkUploadModal .file-icon {
  background: #dde3ec;
  padding: 25px 0;
  border-radius: 6px;
  margin-bottom: 20px;
}
#BulkUploadModal .fileupload {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  padding: 0 10px;
  cursor: pointer;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin: 20px 0;
}
/* #BulkUploadModal .editor{
    width: 100%;
    height: 300px;
    border: 1px solid #D8DEE8;
    border-radius: 8px;
    margin: 15px 0;
} */
#BulkUploadModal .editorbtn {
  float: right;
}
#BulkUploadModal .editorbtn button:focus {
  outline: 0;
}
#BulkUploadModal .editorbtn button {
  border-radius: 4px;
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 40px;
  text-transform: capitalize;
}
#BulkUploadModal .editorbtn button:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
}
#BulkUploadModal .editorbtn button:nth-child(1) {
  background-color: #bcbcbc;
  border: 1px solid #bcbcbc;
  margin-right: 15px;
}
/* Add Create Task Modal */
#AddCreateTaskModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#AddCreateTaskModal .addtas {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 700px;
}
.addtas .customertab .bulk {
  display: flex;
  justify-content: space-between;
}
.addtas .customertab .bulk label:nth-child(1) {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
}
.addtas .customertab .bulk label:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 35px;
  cursor: pointer;
  padding: 0px 18px;
  text-transform: capitalize;
}
.addtas .customertab .mobile {
  display: block;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addtas .customertab input:focus {
  outline: 0;
}
.addtas .customertab .mobileinput {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #595959;
  width: 100%;
  padding: 5px 10px 5px 0px;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addtas .customertab .ant-picker:focus {
  outline: 0;
}
.addtas .customertab .casane {
  display: flex;
}
.addtas .customertab .casane button:focus {
  outline: 0;
}
.addtas .customertab .casane button {
  width: 100%;
  border: 0;
}
.addtas .customertab .casane button:nth-child(2) {
  margin-right: 5px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  background-color: #bcbcbc;
  border-radius: 4px;
  color: #fff;
}
.addtas .customertab .casane button:nth-child(1) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  margin-right: 10px;
}
.addtas .customertab .ant-select {
  width: 100%;
}
.addtas .customertab .ant-select:hover {
  border-bottom: 1px solid #595959;
}
.addtas .customertab .ant-select {
  border-bottom: 1px solid #595959;
}
.addtas .ant-select-selector {
  border: 0 !important;
  border-radius: 0 !important;
  /* border-bottom: 1px solid #595959 !important; */
  width: 100% !important;
  padding: 5px 10px 5px 0px !important;
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}
.addtas .ant-select-arrow {
  color: #000 !important;
}
.addtas
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.addtas .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: transparent !important;
}
.addtas .ant-select-single .ant-select-selector .ant-select-selection-item,
.addtas
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 23px !important;
}
#AddCreateTaskModal .addtas .Sear {
  display: flex;
}
#AddCreateTaskModal .addtas .provide {
  background-color: #2a93ff;
  border-radius: 4px;
  color: #fff;
  color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  padding: 10px 15px 10px 10px;
  margin-top: 5px;
  cursor: pointer;
}
#AddCreateTaskModal .addtas .provide img {
  margin-right: 10px;
}
#AddCreateTaskModal .addtas .cke_bottom {
  display: none;
}
#AddCreateTaskModal .addtas .cke_top {
  background: #fff;
}
#AddCreateTaskModal .addtas .acti .ant-radio-button-wrapper {
  border: 1px solid #2a93ff;
  color: #2a93ff;
  font-family: Lato;
  font-size: 12px;
  line-height: 26px;
  height: auto;
  padding: 2px 10px;
}
#AddCreateTaskModal
  .addtas
  .acti
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
#AddCreateTaskModal
  .addtas
  .acti
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  padding: 2px 10px;
  color: #fff;
  background: #1890ff;
}
#AddCreateTaskModal .addtas .acti img {
  padding: 0 7px 2px 0;
}
.isvisibalePophover {
  display: none;
}
.isvisibalePophover{
    display: none;
}
.expandown img {
    transform: rotate(270deg);
    width: 16px;
    height: 16px;;
}
.expandown1 img {
    transform: rotate(90deg);
    width: 16px;
    height: 16px;
}
/* Mobile View Filter */
.mobbottom {
  display: block!important;
}
.main {
  padding: 10px;
  /* background-image: linear-gradient(180deg,#3c5579,#242f3f); */
  background: #e5e9ed;
  border: 1px solid #e5e9ed;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  display: flex;
  justify-content: center;
}
.item1 {
  text-align: center;
  margin: auto;
}
.background {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: auto;
}
.background img {
  width: 15px;
  padding: 7px 0 0;
}
.mobbottom label {
  font-size: 10px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
.LeadDetail {
    background: #fff;
    width: 95%;
    margin: 15px auto 20px;
    padding: 0;
    border-radius: 8px;
    display: flex;
}
.leaDetLeft {
    width: 31%;
    background: #fff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.leaDetRight {
    width: 69%;
    background: #E9EDF3;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    /* padding: 10px; */
}
.leaDetLeft .LeUpper {
    border-bottom: 1px solid #E7E7E7;
    padding: 15px;
}
.leaDetLeft .LeUpper .leftimg img {
    cursor: pointer;
}
.leaDetLeft .LeUpper .leftimg img:nth-child(1) {
    margin-right: 10px;
}
.LeUpper .userimg {
    text-align: center;
    margin: 20px 0;
}
.LeUpper .userimg img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
}
.LeUpper .userimg h4 {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 0;
}
.LeUpper .userimg h5 {
    color: #000;
    font-family: Lato;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    margin: 0;
}
.LeUpper .userimg h5 img {
    margin: 0;
    width: 18px;
    height: 18px;
    float: right;
    cursor: pointer;
}
.LeUpper .step ul {
    list-style: none;
    margin: 0;
    display: flex;
    margin-bottom: 15px;
}
.LeUpper .step ul li.active {
    border: 1px solid #07A287;
    border-radius: 10px;
    background: #07A287;
}
.LeUpper .step ul li {
    width: 20%;
    height: 8px;
    border: 1px solid #DDE4ED;
    margin-right: 15px;
    border-radius: 10px;
    background: #DDE4ED;
}
.LeUpper .step ul li:last-child {
    margin-right: 0;
}
.leaDetLeft .LeDown {
    padding: 15px;
    margin-top: 10px;
}
.leaDetLeft .LeDown .abou {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    margin: 0;
}
.leaDetLeft .LeDown .dayago {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
}
.LeDown .rightUs {
    display: flex;
}
.LeDown .rightUs img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.LeDown input {
    width: 100%;
    padding: 5px 0;
    height: 30px;
    border-width: 0 0 2px;
    border-color: transparent;
}
.LeDown input:focus {
    /* border-bottom: 1px solid #2187FD; */
    /* border: 0; */
    outline: 0;
    border-color: #2187FD;
    color: #2187FD;
}
.LeDown .Up button:focus {
    outline: 0;
}
.LeDown .Up button {
    color: #2187FD;
    border: 1px solid #2187FD;
    background: transparent;
    font-size: 15px;
    line-height: 16px;
    padding: 6px;
    width: 100%;
    border-radius: 4px;
    font-weight: 500;
}
/* Right Side */
.leaDetRight .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #CAD3DF;
}
.leaDetRight img {
    margin: 0 5px;
}
.leaDetRight .ant-tabs-tab {
    margin-right: 0;
    padding: 15px 20px 15px 5px;
}
.leaDetRight .His {
    cursor: pointer;
}
.leaDetRight .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
    font-weight: 600;
}
.leaDetRight .ant-tabs-tab-btn {
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    cursor: pointer;
}
/* History,Notes Tab CSS */
.HistoryMain,
.NotesMain,
.TaskMain,
.CallMain {
    margin: 0;
    list-style: none;
}
.History,
.Notes,
.Taskk,
.Calll {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    position: relative;
}
.HistoryMain ul li,
.NotesMain ul li,
.TaskMain ul li,
.CallMain ul li {
    margin-bottom: 50px;
}
.HistoryMain ul li:last-child .History::after,
.NotesMain ul li:last-child .Notes::after,
.CallMain ul li:last-child .Calll::after {
    position: absolute;
    content: "";
    border-right: 0;
    height: 100%;
    top: 45px;
    left: 45px;
}
.TaskMain ul li:last-child .Taskk::after {
    position: absolute;
    content: "";
    border-right: 0;
    height: 100%;
    top: 45px;
    left: 45px;
}
.History::after,
.Notes::after,
.Calll::after {
    position: absolute;
    content: "";
    border-right: 1.5px solid #979797;
    height: 100%;
    top: 45px;
    left: 45px;
}
.Taskk::after {
    position: absolute;
    content: "";
    border-right: 1.5px solid #979797;
    height: 100%;
    top: 45px;
    left: 45px;
}
.History .RiSide .rightUs,
.Notes .RiSide .rightUs,
.Taskk .RiSide .rightUs,
.Calll .RiSide .rightUs {
    display: flex;
}
.History .RiSide .rightUs img,
.Notes .RiSide .rightUs img,
.Taskk .RiSide .rightUs img,
.Calll .RiSide .rightUs img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.History .RiSide .rightUs .abou,
.Notes .RiSide .rightUs .abou,
.Taskk .RiSide .rightUs .abou,
.Calll .RiSide .rightUs .abou {
    color: #000000;
    font-family: Lato;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
    width: 85px;
}
.History .RiSide .rightUs .dayago,
.Notes .RiSide .rightUs .dayago,
.Taskk .RiSide .rightUs .dayago,
.Calll .RiSide .rightUs .dayago {
    color: #000000;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
    width: 85px;
}
.History .lefSide .rightUs,
.Notes .lefSide .rightUs,
.Taskk .lefSide .rightUs,
.Calll .lefSide .rightUs {
    display: flex;
}
.History .QuContent,
.Calll .QuContent {
    display: flex;
}
.Calll .QuContent .CallRecord {
    text-align: center;
}
.Notes .QuContent {
    display: flex;
    align-items: center;
}
.History .lefSide .rightUs .QuContent span,
.Notes .lefSide .rightUs .QuContent span {
    width: 35px;
    height: 36px;
    margin-right: 10px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    line-height: 34px;
}
.History .lefSide .rightUs img,
.Notes .lefSide .rightUs img {
    margin: 0;
    width: 15px;
    height: 18px;
}
.History .lefSide .rightUs .dayago,
.Notes .lefSide .rightUs .dayago,
.Taskk .lefSide .rightUs .dayago,
.Calll .lefSide .rightUs .dayago {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin: 0;
}
.History .lefSide .rightUs .abou,
.Notes .lefSide .rightUs .abou,
.Taskk .lefSide .rightUs .abou,
.Calll .lefSide .rightUs .abou {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
}
.History .lefSide .DateTime,
.Notes .lefSide .DateTime,
.Taskk .lefSide .DateTime {
    width: 100px;
}
.Calll .lefSide .DateTime {
    width: 70px;
}
.History .lefSide .Quote,
.Notes .lefSide .Quote,
.Taskk .lefSide .Quote {
    width: 260px;
}
.Calll .lefSide .Quote {
    width: 150px;
}
.History .lefSide .DateTime img,
.Notes .lefSide .DateTime img,
.Taskk .lefSide .DateTime img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}
.History .lefSide .DateTime .dayago,
.Notes .lefSide .DateTime .dayago,
.Taskk .lefSide .DateTime .dayago,
.Calll .lefSide .DateTime .dayago {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    width: auto;
}
.History .lefSide .DateTime .abou,
.Notes .lefSide .DateTime .abou,
.Taskk .lefSide .DateTime .abou,
.Calll .lefSide .DateTime .abou {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
    width: auto;
}
/* Email Tab CSS */
.EmailMain,
.MeetMain {
    padding: 10px 15px;
}
.EmailMain .ToEmai,
.MeetMain .ToEmai {
    display: flex;
}
.SmsMain {
    padding: 0 20px;
}
/* .MeetMain .ToEmai1 {
    display: block;
} */
.EmailMain .ToEmai label,
.MeetMain .ToEmai label {
    color: #4A4A4A;
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    padding: 0 0 4px 0;
    text-transform: capitalize;
}
.MeetMain .ToEmai1 label {
    color: #4A4A4A;
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    padding: 0;
    text-transform: capitalize;
    display: block;
}
.MeetMain .ToEmai1 .ant-picker-focused {
    box-shadow: none;
}
.EmailMain .ToEmai input,
.MeetMain .ToEmai input {
    width: 100%;
    padding: 0 8px 4px 10px;
    height: 30px;
    border-width: 0 0 2px;
    border-color: transparent;
    background: transparent;
}
.MeetMain .ToEmai1 input {
    width: 100%;
    padding: 0 8px 4px 10px;
    height: 30px;
    border-width: 0 0 2px;
    border-color: transparent;
    background: transparent;
}
.EmailMain .ToEmai input:focus,
.MeetMain .ToEmai input:focus {
    outline: 0;
    border-color: #2187FD;
    color: #2187FD;
}
.MeetMain .ToEmai1 input:focus {
    outline: 0;
    border-color: #2187FD;
    color: #2187FD;
}
.MeetMain .ToEmai1 .ant-picker,
.MeetMain .ToEmai1 .ant-select-selector,
.MeetMain .ToEmai1 .ant-select {
    width: 100%;
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 1px solid #4A4A4A !important;
    border-radius: 0 !important;
    padding: 0 !important;
}
.MeetMain .ToEmai1 .ant-picker input {
    padding: 0;
}
.EmailMain .cke_bottom,
.MeetMain .cke_bottom {
    display: none;
}
.EmailMain .cke_chrome,
.MeetMain .cke_chrome {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.EmailMain .cke_inner,
.MeetMain .cke_inner {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.EmailMain .cke_top,
.MeetMain .cke_top {
    background: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.EmailMain .SendBtn button,
.MeetMain .SendBtn button {
    background-color: #2187FD;
    border: 1px solid #2187FD;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 35px;
    cursor: pointer;
    text-transform: uppercase;
    position: absolute;
    right: 25px;
    bottom: 10px;
}
.planned,
.Pastt,
.Completee {
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
    border: 0;
    padding: 5px 15px;
    border-radius: 14px;
    margin-left: 15px;
}
.planned {
    background-color: #EDD200;
    border-radius: 14px;
}
.Pastt {
    background-color: #1F9E00;
    color: #fff;
}
.Completee {
    background-color: #1890ff;
    color: #fff;
}
.EditPro {
    /* float: right; */
    position: absolute;
    right: 45px;
    margin-right: 25px;
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
    border: 0;
    padding: 5px 15px;
    border-radius: 4px;
    background-color: transparent;
    color: #1890ff;
    border: 1px solid #1890ff;
}
.AddTak {
    background: #1890ff;
    color: #fff;
    border: 1px solid #1890ff;
    border-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    padding: 6px;
    font-family: lato;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.SmsMain label {
    color: rgba(0, 0, 0, 0.85);
    font-family: Lato;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin: 0;
    display: block;
    margin-bottom: 10px;
}
.SmsMain textarea {
    display: block;
    width: 100%;
    border: 2px dotted #EDEDED;
    height: 130px;
    border-radius: 6px;
    padding: 5px;
    line-height: 15px;
}
.SmS img {
    width: 18px;
    height: 18px;
}
.SmsMain textarea:focus {
    outline: 0;
}
.SmsMain .cansen .sen {
    background: #1890ff;
    color: #fff;
}
.SmsMain .cansen .can {
    background: #fff;
    color: #1890ff;
    margin-right: 5px;
}
.SmsMain .cansen button {
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 10px 15px;
}
.SmsMain .cansen {
    float: right;
    margin: 10px 0;
}
/* Collapse */
.UserInfoo .ant-collapse {
    border: 0;
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    border-radius: 0;
}
/* .UserInfoo .ant-collapse-content {
    border-top: 0;
} */
.UserInfoo .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 16px 16px 16px;
}
.UserInfoo .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 0;
}
.UserInfoo .anticon svg {
    color: #fff;
    background: #07A287;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 4px 4px;
}
.UserInfoo .LeName:hover {
    background-color: #EDEDED;
}
.UserInfoo .Les {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Lato';
    line-height: 14px;
    color: #07A287;
}
.UserInfoo .LeName label:nth-child(1) {
    font-size: 13px;
    font-weight: 600;
    font-family: 'Lato';
    line-height: 20px;
    color: #4A4A4A;
    width: 115px;
}
.UserInfoo .LeName label:nth-child(2) {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Lato';
    line-height: 20px;
    color: #4A4A4A;
    margin-left: 5px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
/* Divide Sidebar and Content */
.leftside {
    width: 19%;
    /* height: 100vh; */
    float: left;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.rightside {
    width: 81%;
    float: right;
    /* height: 100vh; */
    padding-top: 10px;
}
.Hideleftside {
    width: 4%;
}
.Hiderightside {
    width: 96%;
}
.leftside::-webkit-scrollbar
{
    width: 8px;
    cursor: pointer;
}
.leftside::-webkit-scrollbar-thumb
{
	border-radius: 8px;
    background-color: #D2D2D2;
    cursor: pointer;
}
/* Main Header */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    height: 55px;
    padding: 0 20px;
}
.lefticon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.lefticon img:nth-child(1) {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;
}
.lefticon img:nth-child(2) {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 15px;
    display: none;
}
.lefticon img:nth-child(3) {
    width: 115px;
}
.righticon ul {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}
.righticon ul li {
    margin: 0 10px;
}
.righticon ul li img {
    width: 18px;
    height: 18px;
    cursor: pointer;
}
/* Header Logout Modal */
#LogoutModal .react-responsive-modal-closeButton {
    display: none;
}
#LogoutModal {
    background-color: #FFF;
    border-radius: 8px;
    padding: 18px 25px;
    margin: 0;
    position: absolute;
    top: 65px;
    right: 20px;
}
#LogoutModal .logout {
    display: flex;
    align-items: center;
}
#LogoutModal .logprofile {
    display: flex;
    align-items: center;
}
#LogoutModal .logprofile img {
    width: 50px;
    height: 50px;
}
#LogoutModal .logtext {
    padding: 0 30px 0 12px;
}
#LogoutModal .logtext label {
    color: #000000;
    font-family: Lato;
    font-size: 14px;
    line-height: 15px;
    margin: 0;
    display: block;
    font-weight: 600;
    text-transform: capitalize;
}
#LogoutModal .logtext label img {
    width: 13px;
    height: 13px;
    margin: 0 10px;
    cursor: pointer;
}
#LogoutModal .logtext span {
    color: #4B4B4B;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
}
#LogoutModal .logicon a:focus {
    outline: 0;
}
#LogoutModal .logicon img {
    width: 25px;
    height: 25px;
    cursor: pointer;
}
/* Side Menu */
.sidemenu {
    width: 100%;
    /* background: #fff; */
    height: 100vh;
}
.sidemenu ul {
    list-style: none;
    margin: 0;
}
.sidemenu ul li {
    padding: 8px 15px;
    cursor: pointer;
}
.sidemenu ul li img {
    width: 18px;
    height: 18px;
}
.sidemenu ul li:hover,
.sidemenu ul li:focus {
    background: #E2EFFF;
    font-weight: bold;
}
.sidemenu ul li.active {
    background: #E2EFFF;
    font-weight: bold;
}
.sidemenu ul li label {
    margin: 0;
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 14px;
    padding: 0 10px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    text-transform: capitalize;
}
.Hideleftside .sidemenu ul li label {
    display: none !important;
}
.Hideleftside .sidemenu ul li .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 15px !important;
}
.Hideleftside .ant-menu-submenu-expand-icon, 
.Hideleftside .ant-menu-submenu-arrow {
    right: 3px;
}
.sidemenu .ant-menu-submenu  {
    padding: 0;
}
.sidemenu .ant-menu-submenu-title {
    padding: 8px 15px !important;
}
.sidemenu .ant-menu {
    border-right: 0 !important;
    padding: 0;
}
.sidemenu ul li .ant-menu:hover {
    background: #E2EFFF;
}
.sidemenu ul li .ant-menu-submenu-inline .ant-menu-submenu-title {
    height: auto;
    line-height: 14px;
}
.sidemenu ul li .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: auto;
    line-height: 40px;
}
.sidemenu .ant-menu-sub.ant-menu-inline {
    background: #fff !important;
}
.sidemenu .ant-menu-item {
    margin: 0 !important;
}
.sidemenu .HierSubMenu {
    font-size: 12px;
}
.sidemenu .ant-menu-inline .ant-menu-submenu-title {
    text-overflow: inherit;
}
.sidemenu .ant-menu-item-only-child {
    padding-left: 35px !important;
}
.Hideleftside .ant-menu-inline .ant-menu-item {
    text-overflow: inherit !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
.HierarchyMain,
.TaskTypeMain,
.PriorityMain,
.SplMasterMain,
.LeadStageMain,
.CrmRoleMain,
.CategryMain,
.AlertsMain,
.CustomFieldsMain,
.LocationMain,
.UsersMain,
.NotificationMain,
.DndMain,
.SlaMasterMain,
.LeadScorCusMain {
  padding: 15px 20px;
  background-color: rgb(242, 244, 247);
  height: 100vh;
}
.roleaccessMain {
  padding: 15px 20px;
  background-color: rgb(242, 244, 247);
}
/* Lead,TaskType Header */
.Hierdf {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 15px;
}
.Hierdf .hierleft h4 {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
}
.Hierdf .hierleft h4 img {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.hierdown label img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.hierdown label {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 25px;
  cursor: pointer;
  margin-right: 20px;
  font-weight: 600;
}
.hierright .ant-input-affix-wrapper {
  background-color: #f9f9f9;
  border: 0.5px solid #eaeaea;
  border-radius: 1.5px;
  padding: 6px 10px;
}
.sear .ant-input-affix-wrapper {
  background-color: #fff;
  border: 0.5px solid #eaeaea;
  border-radius: 1.5px;
  padding: 8px 10px;
}
.hierright .ant-input-affix-wrapper > input.ant-input {
  background-color: #f9f9f9;
}
.sear .ant-input-affix-wrapper > input.ant-input {
  background-color: #fff;
}
.hierright .ant-input-affix-wrapper:focus,
.hierright .ant-input-affix-wrapper-focused,
.sear .ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}
/* Hierarchy,TaskType Table */
.HierarchyTable,
.TaskTypeTable,
.LeadStageTable,
.PriorityTable,
.SplMasterTable,
.CrmRolesTable,
.CategiryTable,
.CustomFieldsTable,
.AlertTable,
.LocationTable,
.UsersTable,
.NotificationTable,
.DndTable,
.RoleAccessTable,
.SlaMasterTable,
.LeadScorCusTable {
  margin: 20px 0;
}
.HierarchyTable .ant-table-tbody > tr > td:nth-child(2),
.TaskTypeTable .ant-table-tbody > tr > td:nth-child(2),
.PriorityTable .ant-table-tbody > tr > td:nth-child(2),
.SplMasterTable .ant-table-tbody > tr > td:nth-child(2),
.LeadStageTable .ant-table-tbody > tr > td:nth-child(2),
.CrmRolesTable .ant-table-tbody > tr > td:nth-child(2),
.LocationTable .ant-table-tbody > tr > td:nth-child(2),
.UsersTable .ant-table-tbody > tr > td:nth-child(2),
.NotificationTable .ant-table-tbody > tr > td:nth-child(2),
.DndTable .ant-table-tbody > tr > td:nth-child(2),
.SlaMasterTable .ant-table-tbody > tr > td:nth-child(2),
.LeadScorCusTable .ant-table-tbody > tr > td:nth-child(2),
.CustomFieldsTable .ant-table-tbody > tr > td:nth-child(2),
.AlertTable .ant-table-tbody > tr > td:nth-child(2),
.RoleAccessTable .ant-table-tbody > tr > td:nth-child(2),
.CategiryTable .ant-table-tbody > tr > td:nth-child(2) {
  padding: 0;
}
.HierarchyTable .ant-table,
.TaskTypeTable .ant-table,
.PriorityTable .ant-table,
.SplMasterTable .ant-table,
.CrmRolesTable .ant-table,
.LeadStageTable .ant-table,
.LocationTable .ant-table,
.UsersTable .ant-table,
.NotificationTable .ant-table,
.DndTable .ant-table,
.SlaMasterTable .ant-table,
.LeadScorCusTable .ant-table,
.CustomFieldsTable .ant-table,
.AlertTable .ant-table,
.RoleAccessTable .ant-table,
.CategiryTable .ant-table {
  background-color: #e5e9ed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.HierarchyTable .ant-table-tbody,
.TaskTypeTable .ant-table-tbody,
.PriorityTable .ant-table-tbody,
.SplMasterTable .ant-table-tbody,
.RoleAccessTable .ant-table-tbody,
.CrmRolesTable .ant-table-tbody,
.LeadStageTable .ant-table-tbody,
.LocationTable .ant-table-tbody,
.UsersTable .ant-table-tbody,
.NotificationTable .ant-table-tbody,
.DndTable .ant-table-tbody,
.SlaMasterTable .ant-table-tbody,
.LeadScorCusTable .ant-table-tbody,
.CustomFieldsTable .ant-table-tbody,
.AlertTable .ant-table-tbody,
.CategiryTable .ant-table-tbody {
  background: #fff;
}
.HierarchyTable .ant-table-thead > tr > th,
.RoleAccessTable .ant-table-thead > tr > th,
.TaskTypeTable .ant-table-thead > tr > th,
.PriorityTable .ant-table-thead > tr > th,
.SplMasterTable .ant-table-thead > tr > th,
.CrmRolesTable .ant-table-thead > tr > th,
.LeadStageTable .ant-table-thead > tr > th,
.LocationTable .ant-table-thead > tr > th,
.UsersTable .ant-table-thead > tr > th,
.NotificationTable .ant-table-thead > tr > th,
.DndTable .ant-table-thead > tr > th,
.SlaMasterTable .ant-table-thead > tr > th,
.LeadScorCusTable .ant-table-thead > tr > th,
.CustomFieldsTable .ant-table-thead > tr > th,
.AlertTable .ant-table-thead > tr > th,
.CategiryTable .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  /* padding: 18px 15px; */
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
.HierarchyTable .ant-table-selection-column,
.TaskTypeTable .ant-table-selection-column,
.PriorityTable .ant-table-selection-column,
.SplMasterTable .ant-table-selection-column,
.CrmRolesTable .ant-table-selection-column,
.LocationTable .ant-table-selection-column,
.UsersTable .ant-table-selection-column,
.LeadStageTable .ant-table-selection-column,
.NotificationTable .ant-table-selection-column,
.DndTable .ant-table-selection-column,
.SlaMasterTable .ant-table-selection-column,
.LeadScorCusTable .ant-table-selection-column,
.CustomFieldsTable .ant-table-selection-column,
.RoleAccessTable .ant-table-selection-column,
.AlertTable .ant-table-selection-column,
.CategiryTable .ant-table-selection-column {
  padding: 0 25px !important;
}
.HierarchyTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.TaskTypeTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.RoleAccessTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.PriorityTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.SplMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.CrmRolesTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.LocationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.UsersTable .ant-table-container table > thead > tr:first-child th:first-child,
.NotificationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.DndTable .ant-table-container table > thead > tr:first-child th:first-child,
.LeadStageTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.CustomFieldsTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.CategiryTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.AlertTable .ant-table-container table > thead > tr:first-child th:first-child,
.SlaMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.LeadScorCusTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}
.HierarchyTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.TaskTypeTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.RoleAccessTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.PriorityTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.SplMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.CrmRolesTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.LocationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.UsersTable .ant-table-container table > thead > tr:first-child th:last-child,
.NotificationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.DndTable .ant-table-container table > thead > tr:first-child th:last-child,
.LeadStageTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.CustomFieldsTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.CategiryTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.AlertTable .ant-table-container table > thead > tr:first-child th:last-child,
.SlaMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.LeadScorCusTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
}
.HierarchyTable .ant-table-tbody > tr > td,
.TaskTypeTable .ant-table-tbody > tr > td,
.PriorityTable .ant-table-tbody > tr > td,
.SplMasterTable .ant-table-tbody > tr > td,
.CrmRolesTable .ant-table-tbody > tr > td,
.LocationTable .ant-table-tbody > tr > td,
.UsersTable .ant-table-tbody > tr > td,
.NotificationTable .ant-table-tbody > tr > td,
.DndTable .ant-table-tbody > tr > td,
.LeadStageTable .ant-table-tbody > tr > td,
.SlaMasterTable .ant-table-tbody > tr > td,
.LeadScorCusTable .ant-table-tbody > tr > td,
.CustomFieldsTable .ant-table-tbody > tr > td,
.RoleAccessTable .ant-table-tbody > tr > td,
.AlertTable .ant-table-tbody > tr > td,
.CategiryTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eaeaea;
}
.HierarchyTable .ant-table-tbody > tr.ant-table-row:hover > td,
.TaskTypeTable .ant-table-tbody > tr.ant-table-row:hover > td,
.RoleAccessTable .ant-table-tbody > tr.ant-table-row:hover > td,
.PriorityTable .ant-table-tbody > tr.ant-table-row:hover > td,
.SplMasterTable .ant-table-tbody > tr.ant-table-row:hover > td,
.AlertTable .ant-table-tbody > tr.ant-table-row:hover > td,
.CategiryTable .ant-table-tbody > tr.ant-table-row:hover > td,
.CustomFieldsTable .ant-table-tbody > tr.ant-table-row:hover > td,
.LocationTable .ant-table-tbody > tr.ant-table-row:hover > td,
.UsersTable .ant-table-tbody > tr.ant-table-row:hover > td,
.LeadStageTable .ant-table-tbody > tr.ant-table-row:hover > td,
.NotificationTable .ant-table-tbody > tr.ant-table-row:hover > td,
.DndTable .ant-table-tbody > tr.ant-table-row:hover > td,
.SlaMasterTable .ant-table-tbody > tr.ant-table-row:hover > td,
.LeadScorCusTable .ant-table-tbody > tr.ant-table-row:hover > td,
.CrmRolesTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}
.HierarchyTable .report label,
.RoleAccessTable .report label,
.TaskTypeTable .report label,
.LeadStageTable .report label,
.PriorityTable .report label,
.SplMasterTable .report label,
.AlertTable .report label,
.CategiryTable .report label,
.CustomFieldsTable .report label,
.LocationTable .report label,
.UsersTable .report label,
.NotificationTable .report label,
.DndTable .report label,
.SlaMasterTable .report label,
.LeadScorCusTable .report label,
.CrmRolesTable .report label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Lato;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
.HierarchyTable .Actionic img,
.RoleAccessTable .Actionic img,
.TaskTypeTable .Actionic img,
.LeadStageTable .Actionic img,
.PriorityTable .Actionic img,
.SplMasterTable .Actionic img,
.CategiryTable .Actionic img,
.CustomFieldsTable .Actionic img,
.LocationTable .Actionic img,
.AlertTable .Actionic img,
.UsersTable .Actionic img,
.NotificationTable .Actionic img,
.DndTable .Actionic img,
.SlaMasterTable .Actionic img,
.LeadScorCusTable .Actionic img,
.CrmRolesTable .Actionic img {
  width: 15px;
  height: 15px;
  cursor: pointer;
  vertical-align: baseline;
  vertical-align: initial;
  margin-right: 15px;
}
.HierarchyTable .Status .ant-select-selector,
.RoleAccessTable .Status .ant-select-selector,
.TaskTypeTable .Status .ant-select-selector,
.LeadStageTable .Status .ant-select-selector,
.PriorityTable .Status .ant-select-selector,
.SplMasterTable .Status .ant-select-selector,
.CategiryTable .Status .ant-select-selector,
.CustomFieldsTable .Status .ant-select-selector,
.LocationTable .Status .ant-select-selector,
.AlertTable .Status .ant-select-selector,
.UsersTable .Status .ant-select-selector,
.NotificationTable .Status .ant-select-selector,
.DndTable .Status .ant-select-selector,
.SlaMasterTable .Status .ant-select-selector,
.LeadScorCusTable .Status .ant-select-selector,
.CrmRolesTable .Status .ant-select-selector {
  background-color: #f2f8f8 !important;
  border-radius: 10px !important;
  border: 0 !important;
  color: #07a287;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  height: 25px !important;
  font-weight: 600;
}
.HierarchyTable .Status .ant-select-arrow,
.TaskTypeTable .Status .ant-select-arrow,
.PriorityTable .Status .ant-select-arrow,
.SplMasterTable .Status .ant-select-arrow,
.CategiryTable .Status .ant-select-arrow,
.CustomFieldsTable .Status .ant-select-arrow,
.AlertTable .Status .ant-select-arrow,
.LocationTable .Status .ant-select-arrow,
.UsersTable .Status .ant-select-arrow,
.LeadStageTable .Status .ant-select-arrow,
.NotificationTable .Status .ant-select-arrow,
.DndTable .Status .ant-select-arrow,
.SlaMasterTable .Status .ant-select-arrow,
.LeadScorCusTable .Status .ant-select-arrow,
.CrmRolesTable .Status .ant-select-arrow {
  color: #07a287;
  font-size: 10px;
}
.HierarchyTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.TaskTypeTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.PriorityTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SplMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CategiryTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.AlertTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CustomFieldsTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LocationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.UsersTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.NotificationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadStageTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.DndTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CrmRolesTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SlaMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadScorCusTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.HierarchyTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.TaskTypeTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.PriorityTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SplMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CategiryTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.AlertTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CustomFieldsTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LocationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.UsersTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.NotificationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadStageTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.DndTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CrmRolesTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SlaMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadScorCusTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 26px !important;
  color: #07a287;
  font-size: 10px;
}
.HierarchyTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.TaskTypeTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.PriorityTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SplMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CategiryTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.AlertTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CustomFieldsTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LocationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.UsersTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadStageTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.NotificationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CrmRolesTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SlaMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadScorCusTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.HierarchyTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.TaskTypeTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.PriorityTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SplMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CategiryTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.AlertTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CustomFieldsTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LocationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.UsersTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.NotificationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadStageTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CrmRolesTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SlaMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadScorCusTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 26px !important;
}
/* Create Hierarchy,Create Task Modal */
#CreateHierarchyModal .react-responsive-modal-closeButton,
#CreateTaskTyModal .react-responsive-modal-closeButton,
#CreatePriorityModal .react-responsive-modal-closeButton,
#CreateSplMasterModal .react-responsive-modal-closeButton,
#CreateCrmRoleModal .react-responsive-modal-closeButton,
#CreateCategoryModal .react-responsive-modal-closeButton,
#CreateAlertModal .react-responsive-modal-closeButton,
#CreateLocationModal .react-responsive-modal-closeButton,
#CreateCustomFieldsModal .react-responsive-modal-closeButton,
#CreateUserModal .react-responsive-modal-closeButton,
#CreateNotificationModal .react-responsive-modal-closeButton,
#CreateDndModal .react-responsive-modal-closeButton,
#CreateLeadStagManaModal .react-responsive-modal-closeButton,
#CreateSlaMasterModal .react-responsive-modal-closeButton,
#SelectTempModal .react-responsive-modal-closeButton,
#CreateLeadScoModal .react-responsive-modal-closeButton,
#CreateAlertTempModal .react-responsive-modal-closeButton {
  display: none;
}
#CreateHierarchyModal,
#CreateTaskTyModal,
#CreatePriorityModal,
#CreateSplMasterModal,
#CreateCrmRoleModal,
#CreateCategoryModal,
#CreateAlertModal,
#CreateLocationModal,
#CreateCustomFieldsModal,
#CreateUserModal,
#CreateNotificationModal,
#CreateDndModal,
#CreateLeadStagManaModal,
#CreateSlaMasterModal,
#CreateLeadScoModal,
#SelectTempModal,
#CreateAlertTempModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#CreateHierarchyModal .createHier,
#CreatePriorityModal .createPrior,
#CreateSplMasterModal .createSplMas,
#CreateCrmRoleModal .createCrmRole,
#CreateCategoryModal .createCategory,
#CreateAlertModal .createAlerts,
#CreateNotificationModal .createNoti,
#CreateLeadStagManaModal .createLeadStag {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
#CreateDndModal .createDnd{
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 715px;
  border-bottom: 1px solid #dedede;
}
#CreateAlertTempModal .createAlerts1 {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
#CreateUserModal .createUser {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 650px;
  height: 450px;
  border-bottom: 1px solid #dedede;
}
#CreateLocationModal .createLocation {
  position: relative;
}
#CreateCustomFieldsModal .createCustomFields,
#CreateLocationModal .createLocation,
#CreateAlertModal .createAlerts {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
#CreateSlaMasterModal .createSlaMa,
#CreateLeadScoModal .createLeaScoCus {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 650px;
  border-bottom: 1px solid #dedede;
}
#CreateTaskTyModal .createTas {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
.createHier .hier label,
.createTas .hier label,
.createPrior .hier label,
.createSplMas .hier label,
.createCrmRole .hier label,
.createCategory .hier label,
.createLocation .hier label,
.createCustomFields .hier label,
.createDnd .hier label,
.createNoti .hier label,
.createLeadStag .hier label,
.createLeaScoCus .hier label,
.createSlaMa .hier label,
.createAlerts .hier label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
}
.createAlerts .hier1 label {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
}
.createHier .desig,
.createTas .desig,
.createPrior .desig,
.createSplMas .desig,
.createCrmRole .desig,
.createCategory .desig,
.createLocation .desig,
.createCustomFields .desig,
.createDnd .desig,
.createNoti .desig,
.createLeadStag .desig,
.createSlaMa .desig,
.createLeaScoCus .desig,
.createUser .desig {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  display: block;
}
.createSlaMa .dndChe {
  float: right;
}
.createSlaMa .dndChe span {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
}
.createHier .single {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
}
.createUser .ant-picker,
.createTas .ant-picker {
  padding: 8px;
  background-color: #f9f9f9;
}
.createHier input:focus,
.createTas input:focus,
.createTas textarea:focus,
.createPrior input:focus,
.createSplMas input:focus,
.createCrmRole input:focus,
.createCategory input:focus,
.createNoti input:focus,
.createDnd input:focus,
.createUser input:focus,
.createNoti textarea:focus,
.createLocation input:focus,
.createAlerts input:focus,
.createCustomFields input:focus,
.createLocation textarea:focus,
.createLeadStag input:focus,
.createSlaMa input:focus,
.createLeaScoCus input:focus {
  outline: 0;
}
.createHier input,
.createTas input,
.createTas textarea,
.createPrior input,
.createSplMas input,
.createCrmRole input,
.createCategory input,
.createNoti input,
.createUser input,
.createLocation input,
.createAlerts input,
.createCustomFields input,
.createLocation textarea,
.createDnd input,
.createLeadStag input,
.createSlaMa input,
.createLeaScoCus input,
.createNoti textarea {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 8px;
  background: #f9f9f9;
}
.createHier .ant-select,
.createTas .ant-select,
.createPrior .ant-select,
.createCrmRole .ant-select,
.createCategory .ant-select,
.createNoti .ant-select,
.createDnd .ant-select,
.createUser .ant-select,
.createLeadStag .ant-select,
.createLocation .ant-select,
.createLeaScoCus .ant-select,
.createAlerts .ant-select,
.createCustomFields .ant-select,
.createSlaMa .ant-select,
.hierdown1 .ant-select {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 40px;
  background: #f9f9f9;
}
.sear .ant-select {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 40px;
  background: #fff;
}
.createHier .ant-select-single .ant-select-selector .ant-select-selection-item,
.createTas .ant-select-single .ant-select-selector .ant-select-selection-item,
.createLeadStag
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createPrior .ant-select-single .ant-select-selector .ant-select-selection-item,
.createCategory
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createCrmRole
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createNoti .ant-select-single .ant-select-selector .ant-select-selection-item,
.createDnd .ant-select-single .ant-select-selector .ant-select-selection-item,
.createUser .ant-select-single .ant-select-selector .ant-select-selection-item,
.createLocation
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createAlerts
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createSlaMa .ant-select-single .ant-select-selector .ant-select-selection-item,
.createLeaScoCus
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.sear .ant-select-single .ant-select-selector .ant-select-selection-item,
.hierdown1 .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}
.createHier
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createTas
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createPrior
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createCategory
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createUser
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createLocation
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createAlerts
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createNoti
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createDnd
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createLeadStag
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createCrmRole
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createSlaMa
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createLeaScoCus
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.sear
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.hierdown1
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.createHier .ant-select-selector,
.createTas .ant-select-selector,
.createPrior .ant-select-selector,
.createCategory .ant-select-selector,
.createUser .ant-select-selector,
.createLocation .ant-select-selector,
.createAlerts .ant-select-selector,
.createCustomFields .ant-select-selector,
.createNoti .ant-select-selector,
.createDnd .ant-select-selector,
.createLeadStag .ant-select-selector,
.createCrmRole .ant-select-selector,
.createLeaScoCus .ant-select-selector,
.createSlaMa .ant-select-selector,
.hierdown1 .ant-select-selector {
  border: 0 !important;
  background-color: #f9f9f9 !important;
}
.sear .ant-select-selector {
  border: 0 !important;
  background-color: #fff !important;
}
.createCrmRole .fullacces {
  color: #007aff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  font-family: lato;
  padding-top: 10px;
}
#CreateHierarchyModal .editorbtn,
#CreateTaskTyModal .editorbtn,
#CreatePriorityModal .editorbtn,
#CreateSplMasterModal .editorbtn,
#CreateCrmRoleModal .editorbtn,
#CreateCategoryModal .editorbtn,
#CreateAlertModal .editorbtn,
#CreateNotificationModal .editorbtn,
#CreateDndModal .editorbtn,
#CreateLeadStagManaModal .editorbtn,
#CreateUserModal .editorbtn,
#CreateCustomFieldsModal .editorbtn,
#CreateLocationModal .editorbtn,
#CreateLeadScoModal .editorbtn,
#CreateSlaMasterModal .editorbtn {
  float: right;
}
#CreateHierarchyModal .editorbtn button:focus,
#CreateTaskTyModal .editorbtn button:focus,
#CreatePriorityModal .editorbtn button:focus,
#CreateSplMasterModal .editorbtn button:focus,
#CreateCategoryModal .editorbtn button:focus,
#CreateAlertModal .editorbtn button:focus,
#CreateCrmRoleModal .editorbtn button:focus,
#CreateNotificationModal .editorbtn button:focus,
#CreateDndModal .editorbtn button:focus,
#CreateLeadStagManaModal .editorbtn button:focus,
#CreateUserModal .editorbtn button:focus,
#CreateCustomFieldsModal .editorbtn button:focus,
#CreateLocationModal .editorbtn button:focus,
#CreateLeadScoModal .editorbtn button:focus,
#CreateSlaMasterModal .editorbtn button:focus {
  outline: 0;
}
#CreateHierarchyModal .editorbtn button,
#CreateTaskTyModal .editorbtn button,
#CreatePriorityModal .editorbtn button,
#CreateSplMasterModal .editorbtn button,
#CreateCategoryModal .editorbtn button,
#CreateAlertModal .editorbtn button,
#CreateCrmRoleModal .editorbtn button,
#CreateNotificationModal .editorbtn button,
#CreateDndModal .editorbtn button,
#CreateLeadStagManaModal .editorbtn button,
#CreateUserModal .editorbtn button,
#CreateCustomFieldsModal .editorbtn button,
#CreateLocationModal .editorbtn button,
#CreateLeadScoModal .editorbtn button,
#CreateSlaMasterModal .editorbtn button {
  border-radius: 4px;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 40px;
  text-transform: capitalize;
  margin: 20px 0;
}
#CreateHierarchyModal .editorbtn button:nth-child(2),
#CreateTaskTyModal .editorbtn button:nth-child(2),
#CreatePriorityModal .editorbtn button:nth-child(2),
#CreateSplMasterModal .editorbtn button:nth-child(2),
#CreateCategoryModal .editorbtn button:nth-child(2),
#CreateAlertModal .editorbtn button:nth-child(2),
#CreateCrmRoleModal .editorbtn button:nth-child(2),
#CreateNotificationModal .editorbtn button:nth-child(2),
#CreateDndModal .editorbtn button:nth-child(2),
#CreateLeadStagManaModal .editorbtn button:nth-child(2),
#CreateUserModal .editorbtn button:nth-child(2),
#CreateCustomFieldsModal .editorbtn button:nth-child(2),
#CreateLocationModal .editorbtn button:nth-child(2),
#CreateLeadScoModal .editorbtn button:nth-child(2),
#CreateSlaMasterModal .editorbtn button:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  margin-right: 15px;
  color: #ffffff;
}
#CreateHierarchyModal .editorbtn button:nth-child(1),
#CreateTaskTyModal .editorbtn button:nth-child(1),
#CreatePriorityModal .editorbtn button:nth-child(1),
#CreateSplMasterModal .editorbtn button:nth-child(1),
#CreateCategoryModal .editorbtn button:nth-child(1),
#CreateAlertModal .editorbtn button:nth-child(1),
#CreateCrmRoleModal .editorbtn button:nth-child(1),
#CreateNotificationModal .editorbtn button:nth-child(1),
#CreateDndModal .editorbtn button:nth-child(1),
#CreateLeadStagManaModal .editorbtn button:nth-child(1),
#CreateUserModal .editorbtn button:nth-child(1),
#CreateLocationModal .editorbtn button:nth-child(1),
#CreateLeadScoModal .editorbtn button:nth-child(1),
#CreateCustomFieldsModal .editorbtn button:nth-child(1),
#CreateSlaMasterModal .editorbtn button:nth-child(1) {
  background-color: transparent;
  border: 1px solid #bcbcbc;
  color: #4a4a4a;
  margin-right: 15px;
}
#CreateDndModal .addnew button {
  border: 1px solid #007aff;
  color: #007aff;
  border-radius: 4px;
  font-family: Lato;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 10px;
  text-transform: capitalize;
  background: transparent;
  font-weight: 600;
}
#CreateLeadStagManaModal .addStatusBtn,
#CreateLeadScoModal .addStatusBtn {
  border: 1px solid #007aff;
  border-radius: 2px;
  width: 100%;
  height: 27px;
  color: #007aff;
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #fff;
  font-weight: 600;
}
#CreateLeadStagManaModal .imgdrag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#CreateLeadStagManaModal .imgdrag img {
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin-top: 5px;
}
.createSlaMa .card {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 10px;
  background: #f9f9f9;
}
.createSlaMa .card .pro {
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  margin-bottom: 10px;
}
.createSlaMa .card .low {
  font-size: 13px;
  font-weight: 500;
  display: block;
  color: #a7a7a7;
  font-family: Lato;
  line-height: 25px;
  height: 22px;
  margin-bottom: 10px;
}
.createSlaMa .card .high {
  display: flex;
  margin-bottom: 10px;
}
.createSlaMa .card input {
  height: 22px;
}
.createSlaMa .high .ant-select-selector {
  background-color: #fff !important;
}
.createSlaMa .high .ant-select {
  width: 100%;
  border: 0;
  border-radius: 4px;
  height: 22px;
  background: #fff;
  margin-right: 10px;
}
.createSlaMa
  .high
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 22px;
}
.createSlaMa
  .high
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 22px;
  border: 1px solid #ededed !important;
}
.createLeaScoCus .hott {
  display: flex;
}
.createLeaScoCus .hott label {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.createLeaScoCus .cold {
  background: #98e5ff;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.createLeaScoCus .warm {
  background: #ffe050;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.createLeaScoCus .hot {
  background: #ff3200;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.createCustomFields .mand {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  margin: 0 10px 8px 0 !important;
}
/* Download and Upload */
#CreateHierarchyModal .drop,
#CreateTaskTyModal .drop,
#CreateCrmRoleModal .drop,
#CreateDndModal .drop,
#CreateCategoryModal .drop,
#CreateAlertModal .drop,
#CreateUserModal .drop,
#CreateSlaMasterModal .drop,
#CreateCustomFieldsModal .drop,
#CreateLocationModal .drop,
#CreateCrmRoleModal .drop {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#CreateHierarchyModal .down,
#CreateTaskTyModal .down,
#CreateCrmRoleModal .down,
#CreateDndModal .down,
#CreateCategoryModal .down,
#CreateAlertModal .down,
#CreateUserModal .down,
#CreateSlaMasterModal .down,
#CreateCustomFieldsModal .down,
#CreateLocationModal .down,
#CreateCrmRoleModal .down {
  display: block;
  color: #2187fd;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#CreateHierarchyModal .mainfileUpload div:focus,
#CreateTaskTyModal .mainfileUpload div:focus,
#CreateCrmRoleModal .mainfileUpload div:focus,
#CreateDndModal .mainfileUpload div:focus,
#CreateCategoryModal .mainfileUpload div:focus,
#CreateAlertModal .mainfileUpload div:focus,
#CreateUserModal .mainfileUpload div:focus,
#CreateSlaMasterModal .mainfileUpload div:focus,
#CreateCustomFieldsModal .mainfileUpload div:focus,
#CreateLocationModal .mainfileUpload div:focus,
#CreateCrmRoleModal .mainfileUpload div:focus {
  outline: 0;
}
#CreateHierarchyModal .mainfileUpload,
#CreateTaskTyModal .mainfileUpload,
#CreateDndModal .mainfileUpload,
#CreateTaskTyModal .mainfileUpload,
#CreateCategoryModal .mainfileUpload,
#CreateAlertModal .mainfileUpload,
#CreateUserModal .mainfileUpload,
#CreateSlaMasterModal .mainfileUpload,
#CreateCustomFieldsModal .mainfileUpload,
#CreateLocationModal .mainfileUpload,
#CreateCrmRoleModal .mainfileUpload {
  width: 100%;
  height: 250px;
  border: 3px dotted #1484ff;
  border-radius: 8px;
  margin: 15px 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9edf3;
}
#CreateHierarchyModal .file-icon img,
#CreateTaskTyModal .file-icon img,
#CreateCrmRoleModal .file-icon img,
#CreateDndModal .file-icon img,
#CreateCategoryModal .file-icon img,
#CreateAlertModal .file-icon img,
#CreateUserModal .file-icon img,
#CreateSlaMasterModal .file-icon img,
#CreateCustomFieldsModal .file-icon img,
#CreateLocationModal .file-icon img,
#CreateCrmRoleModal .file-icon img {
  width: 30px;
  height: 30px;
}
#CreateHierarchyModal .file-icon,
#CreateTaskTyModal .file-icon,
#CreateCrmRoleModal .file-icon,
#CreateDndModal .file-icon,
#CreateCategoryModal .file-icon,
#CreateAlertModal .file-icon,
#CreateUserModal .file-icon,
#CreateSlaMasterModal .file-icon,
#CreateCustomFieldsModal .file-icon,
#CreateLocationModal .file-icon,
#CreateCrmRoleModal .file-icon {
  background: #dde3ec;
  padding: 25px 0;
  border-radius: 6px;
  margin-bottom: 20px;
}
#CreateHierarchyModal .fileupload,
#CreateTaskTyModal .fileupload,
#CreateCrmRoleModal .fileupload,
#CreateDndModal .fileupload,
#CreateCategoryModal .fileupload,
#CreateAlertModal .fileupload,
#CreateUserModal .fileupload,
#CreateSlaMasterModal .fileupload,
#CreateCustomFieldsModal .fileupload,
#CreateLocationModal .fileupload,
#CreateCrmRoleModal .fileupload {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  padding: 0 10px;
  cursor: pointer;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin: 20px 0;
}
/* Step Progress Bar */
#CreateLocationModal .createLocation .previousBtn {
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  background: #f9f9f9;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 30px;
}
#CreateUserModal .createUser .ant-steps-label-vertical .ant-steps-item-content,
#CreateCustomFieldsModal
  .createCustomFields
  .ant-steps-label-vertical
  .ant-steps-item-content,
#CreateLocationModal
  .createLocation
  .ant-steps-label-vertical
  .ant-steps-item-content {
  margin-top: -30px !important;
}
#CreateUserModal .createUser .ant-steps-item-title,
#CreateCustomFieldsModal .createCustomFields .ant-steps-item-title,
#CreateLocationModal .createLocation .ant-steps-item-title {
  font-size: 12px;
  color: #4a4a4a;
  line-height: 12px;
  font-family: lato;
}
#CreateUserModal .createUser .Steep {
  margin: 30px auto;
  width: 100%;
}
#CreateLocationModal .createLocation .Steep {
  margin: 30px auto;
  width: 80%;
}
#CreateLocationModal
  .createLocation
  .ant-steps-item-finish
  .ant-steps-item-title,
#CreateUserModal .createUser .ant-steps-item-finish .ant-steps-item-title,
#CreateLocationModal
  .createLocation
  .ant-steps-item-active
  .ant-steps-item-title {
  color: #1484ff !important;
}
#CreateUserModal .createUser .ant-steps-dot .ant-steps-item-tail::after,
#CreateLocationModal
  .createLocation
  .ant-steps-dot
  .ant-steps-item-tail::after {
  width: calc(100% - 8px);
  height: 3px;
  margin-left: 5px;
}
.createAlerts1 .hier label {
  font-size: 16px;
  color: #4A4A4A;
  font-weight: 600;
  font-family: lato;
}
.createAlerts1 .hier label span {
  font-weight: 500;
}
.createAlerts1 .ant-tabs-nav-wrap {
  background: #EDEDED;
  border-radius: 6px;
  justify-content: center;
}
.createAlerts1 .ant-tabs-tab {
  margin: 0;
  padding: 12px;
  cursor: pointer;
  font-weight: 600;
  color: #4A4A4A;
}
.createAlerts1 .ant-tabs-tab label {
  cursor: pointer;
}
.createAlerts1 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
}
.createAlerts1 .Ema .Compose {
  font-size: 16px;
  font-weight: 500;
  color: #4A4A4A;
  line-height: 20px;
}
.createAlerts1 .Ema .Card {
  border: 1px solid #EDEDED;
  width: 100%;
  height: 200px;
  margin: 15px 0;
  border-radius: 6px;
  padding: 10px;
}
.createAlerts1 .Ema .SavBtn button {
  background: #1484ff;
  color: #fff;
  border-radius: 6px;
  padding: 6px 25px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  float: right;
}
/* Template Screen CSS */
.TemplateMain {
  padding: 0;
  background-color: rgb(242, 244, 247);
}
.TabTemp .ant-tabs-nav {
  background: #f9f9f9;
  margin-bottom: 40px !important;
}
.TabTemp .ant-tabs-nav-list {
  margin-left: 20px;
}
.TabTemp .ant-tabs-ink-bar {
  display: none;
}
.TabTemp .ant-tabs-tab label {
  cursor: pointer;
}
.TabTemp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #007aff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  cursor: pointer;
}
.TabTemp .ant-tabs-tab-btn {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
}
.SaveExit {
  position: absolute;
  right: 15px;
  top: 69px;
}
.SaveExit button {
  font-size: 14px;
  font-weight: 500;
  font-family: lato;
  padding: 6px 20px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
}
.SaveExit button:nth-child(1) {
  background: transparent;
  border: 1px solid #979797;
  color: #979797;
  margin-right: 10px;
}
.SaveExit button:nth-child(2) {
  background: #007aff;
  border: 1px solid #007aff;
  color: #fff;
}
.configure {
  background: #fff;
  width: 80%;
  margin: 0 auto 20px;
  padding: 20px 50px;
  border-radius: 6px;
}
.configure .creat {
  color: #4a4a4a;
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 15px;
}
.configure .desig {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  display: block;
}
.configure .desig1 {
  visibility: hidden;
}
.configure .desig2 {
  text-align: left;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #007aff;
  cursor: pointer;
}
.configure .desig3 {
  text-align: left;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #7b7b7b;
}
.configure .desig3 span {
  margin-left: 10px;
}
.configure input:focus {
  outline: 0;
}
.configure input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 8px;
  background: #f9f9f9;
}
.configure .ant-select {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 40px;
  background: #f9f9f9;
}
.configure .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}
.configure
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.configure .ant-select-selector {
  border: 0 !important;
  background-color: #f9f9f9 !important;
}
.configure .Smile {
  float: right;
  width: 15px;
  height: 15px;
  margin-top: 5px;
}
.configure .switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 25px;
  margin-left: 30px;
}
.configure .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.configure .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.configure .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
.configure input:checked + .slider {
  background-color: #2196f3;
}
.configure input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.configure input:checked + .slider:before {
  transform: translateX(26px);
}
.configure .slider.round {
  border-radius: 34px;
}
.configure .slider.round:before {
  border-radius: 50%;
}
/* Style Tab */
.style .ant-tabs-nav {
  background: #fff;
  margin: 0 auto 25px !important;
  border-radius: 5px !important;
}
.style .ant-tabs-tab-btn {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 10px;
}
.style .ant-tabs-tab {
  margin-right: 15px;
}
.style .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #007aff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  padding: 8px 10px;
  border-radius: 4px;
}
.Theme {
  background: #f9f9f9;
  width: 95%;
  margin: 0 auto 20px;
  padding: 20px 50px;
  border-radius: 6px;
}
.Theme .card {
  background-color: #ffffff;
  border-radius: 5px;
  border: 0px solid #ededed;
  padding: 20px;
  cursor: pointer;
}
.Theme .card .text {
  color: #4a4a4a;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  margin-top: 22px;
}
.Theme .card:hover .conthov {
  background-color: #000000;
  opacity: 0.422374636627907;
}
.Theme .card .conthov {
  transition: 0.5s;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 245px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Theme .card .conthov button:nth-child(1) {
  color: #939393;
  background: #fff;
  border: 1px solid #939393;
  border-radius: 4px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;
}
.Theme .card .conthov button:nth-child(2) {
  color: #fff;
  background: #007aff;
  border: 1px solid #007aff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
}
.Theme .card .im {
  width: 100%;
  height: 170px;
  background: #f3f3f3;
}
#SelectTempModal .createTempl {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
}
.createTempl label {
  color: #9b9b9b;
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.createTempl .threebtn {
  float: right;
  margin-top: 30px;
}
.createTempl .threebtn button:nth-child(1) {
  background: transparent;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  margin-right: 10px;
}
.createTempl .threebtn button:nth-child(2) {
  background: transparent;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  margin-right: 10px;
}
.createTempl .threebtn button:nth-child(3) {
  background: #007aff;
  color: #fff;
  border: 1px solid #007aff;
}
.createTempl .threebtn button {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 8px 10px;
  border-radius: 4px;
}
.spanTbl {
  font-size: 9px;
  margin-left: 5px;
  color: #5dabff;
}
.printImg {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.dndDropDown{
  width: 80px !important;
  height: 30px !important;
}
.dndDropDown span.ant-select-selection-item {
  line-height: 30px !important;
}
.dndDropDown1 .desig {
  display: inline-block !important;
  margin: 0 5px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
/* Change the input to background color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #ECF2F4 inset !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
}
.Hiderightside .leaDetLeft {
    width: 30%;
}
.Hiderightside .leaDetRight {
    width: 70%;
}
.Hiderightside .lefSide .Quote {
    width: 435px;
}
.Hiderightside .Calll .lefSide .Quote {
    width: 150px;
}
.Hiderightside .HistoryMain ul li {
    margin-bottom: 50px;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
    header {
        padding: 0 15px;
    }
    header .lefticon img:nth-child(3) {
        width: 90px;
    }
    header .righticon ul li img {
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
    header .righticon ul li {
        margin: 0 10px;
    }
    header .righticon ul li:last-child {
        margin-right: 0;
    }
    .leftside,
    .lead .leadright {
        display: none;
    }
    .ant-drawer-body {
        padding: 0 !important;
    }
    .rightside,
    .leftside {
        float: none;
    }
    .rightside {
        width: 100%;
    }
    .sidemenu ul li {
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #EDEDED;
    }
    .sidemenu ul li .ant-menu-submenu-inline .ant-menu-submenu-title {
        padding: 7px 7px !important;
        cursor: pointer;
    }
    .lefticon img:nth-child(1) {
        display: none;
    }
    .lefticon img:nth-child(2) {
        display: block;
    }

    .leadsmain {
        padding: 15px 10px;
    }
    .LeadTable .MobViewNone {
        display: none;
    }
    .LeadTable .ant-table-tbody > tr > td {
       padding: 16px 6px !important; 
    }
    .Fillte {
        display: block;
        /* float: right; */
    }
    .LeadTable {
        margin: 20px 0 60px 0;
    }
    .cards .leadate {
        margin: 0;
    }
    .cards .leadate, 
    .cards .leadate1, 
    .cards .leadate2 {
        font-size: 13px;
    }
    .cards .card:nth-child(1) {
        margin: 0 0 30px 0;
    }
    .cards .cads {
        display: block;
    }
    .cads .card .usnam1 label:nth-child(1) {
        font-size: 10px;
        width: 55px;
    }
    .cads .card .usnam label:nth-child(1) {
        font-size: 10px;
        width: 84px;
    }
    .cads .card .usnam1 label:nth-child(2) {
        font-size: 10px;
    }
    .cads .card .usnam label:nth-child(2) {
        font-size: 10px;
    }
    .cards .card {
        padding: 10px;
    }
    .cards {
        padding: 15px 10px;
    }
    .mobbottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        display: block!important;
    }
    .LeadTable .Conat {
        font-size: 12px;
        font-weight: 600;
        color: #4A4A4A;
        line-height: 20px;
        margin: 0;
        padding: 0;
        display: block;
    }
    .LeadTable .Conat1 {
        font-size: 11px;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 12px;
        margin: 0;
        padding: 0;
        display: block;
    }
    .LeadTable .Conat12 {
        font-size: 11px;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 12px;
        margin: 0;
        padding: 0;
        display: inline-block;
    }
    .LeadTable .DropStatusimg {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        cursor: pointer;
    }
    #AddLeadTaskModal .addlead {
        height: 450px;
        overflow-y: auto;
    }
    #AddLeadTaskModal {
        max-width: 92%;
    }
    .addlead .ant-tabs-nav {
        width: 100%;
    }
    .addlead .ant-tabs-tab-active {
        height: 40px;
        width: auto;
        font-size: 12px;
        line-height: 19px;
        background-size: 14px 14px;
        background-position: 10px center;
        padding: 10px 10px 10px 30px !important;
    }
    .addlead .ant-tabs-tab {
        margin-bottom: 0 !important;
        height: 40px;
        width: auto;
        font-size: 12px;
        line-height: 19px;
        background-size: 14px 14px;
        background-position: 10px center;
        padding: 10px 10px 10px 30px !important;
    }
    .addlead .ant-tabs-nav-list {
        padding: 15px 10px;
    }
    .addlead .ant-tabs-tab {
        margin-right: 10px;
    }
    .addlead .ant-tabs-content-holder {
        background: #fff;
        padding: 10px 15px 30px 15px;
    }
    .addlead .customertab .bulk label:nth-child(1) {
        font-size: 14px;
        line-height: 30px;
    }
    .addlead .customertab .bulk label:nth-child(2) {
        height: 30px;
        font-size: 13px;
        line-height: 29px;
        padding: 0px 10px;
    }
    .addlead .ant-tabs-top > .ant-tabs-nav {
        margin-bottom: 0;
    }
    .addlead .customertab .mobileinput {
        margin-bottom: 10px;
    }
    #BulkUploadModal .addnote {
        width: 300px;
    }
    #BulkUploadModal {
        padding: 15px 30px;
    }
    #BulkUploadModal .fileupload {
        margin: 15px 0;
    }
    #BulkUploadModal .file-icon {
        padding: 15px 0;
        margin-bottom: 15px;
    }
    #BulkUploadModal .mainfileUpload {
        height: 230px;
    }
    #BulkUploadModal .addnote {
        height: 350px;
    }
    .LeadDetailMobile .Notes .ant-collapse {
        border: 0;
    }
    .LeadDetailMobile .leaDetLeft {
        width: 100%;
    }
    .LeadDetailMobile .leaDetRight {
        width: 100%;
    }
    .LeadDetailMobile .LeadDetail {
        display: block;
    }
    .LeadDetailMobile .NotesMain ul li,
    .LeadDetailMobile .HistoryMain ul li,
    .LeadDetailMobile .TaskMain ul li,
    .LeadDetailMobile .CallMain ul li {
        margin-bottom: 20px;
    }
    .LeadDetailMobile .NotesMain .anticon svg,
    .LeadDetailMobile .HistoryMain .anticon svg,
    .LeadDetailMobile .TaskMain .anticon svg,
    .LeadDetailMobile .CallMain .anticon svg {
        color: #fff;
        background: #07A287;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 4px 4px;
    }
    .LeadDetailMobile .NotesMain .pannel,
    .LeadDetailMobile .HistoryMain .pannel,
    .LeadDetailMobile .TaskMain .pannel,
    .LeadDetailMobile .CallMain .pannel {
        margin-left: 8px;
    }
    .LeadDetailMobile .Notes::after,
    .LeadDetailMobile .History::after,
    .LeadDetailMobile .TaskMain::after,
    .LeadDetailMobile .CallMain::after {
        position: absolute;
        content: "";
        border-right: 0;
        height: 100%;
        top: 45px;
        left: 45px;
    }
    .LeadDetailMobile .Notes,
    .LeadDetailMobile .History,
    .LeadDetailMobile .Taskk,
    .LeadDetailMobile .Calll {
        padding: 0;
    }
    .LeadDetailMobile .History .QuContent img,
    .LeadDetailMobile .Notes .QuContent img {
        margin: 0;
        width: 15px;
        height: 18px;
    }
    .LeadDetailMobile .History .QuContent span,
    .LeadDetailMobile .Notes .QuContent span {
        width: 35px;
        height: 36px;
        margin-right: 10px;
        border-radius: 50%;
        background: #EDEDED;
        text-align: center;
        line-height: 34px;
    }
    .LeadDetailMobile .pannel .DateTime .abou,
    .LeadDetailMobile .History .Quote .abou,
    .LeadDetailMobile .TaskMain .DateTime .abou,
    .LeadDetailMobile .TaskMain .Taskk .QuContent .dayago span,
    .LeadDetailMobile .CallMain .DateTime .abou,
    .LeadDetailMobile .CallMain .Calll .QuContent .abou {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
    }
    .LeadDetailMobile .pannel .DateTime .dayago,
    .LeadDetailMobile .TaskMain .pannel .DateTime .dayago,
    .LeadDetailMobile .TaskMain .Taskk .QuContent .dayago,
    .LeadDetailMobile .CallMain .pannel .DateTime .dayago,
    .LeadDetailMobile .CallMain .Calll .QuContent .dayago {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
    }
    .LeadDetailMobile .Notes .DateTime .dayago,
    .LeadDetailMobile .History .DateTime .dayago {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }
    .LeadDetailMobile .Notes .QuContent .Quote .dayago,
    .LeadDetailMobile .History .QuContent .Quote .dayago {
        color: rgba(0, 0, 0, 0.85);
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
    }
    .addlead .customertab .ant-select {
        margin-bottom: 10px;
    }
    .LeadDetailMobile .TaskMain .pannel .lefSide {
        display: flex;
        justify-content: space-between;
    }
    .LeadDetailMobile .EditPro {
        position: unset;
        margin: 0 0 10px 10px;
    }
    .LeadDetailMobile .planned,
    .LeadDetailMobile .Pastt, 
    .LeadDetailMobile .Completee {
        height: 26px;;
    }
    #AddNotesModal {
        width: 90%;
    }
    #AddCreateTaskModal {
        width: 90%;
        height: 400px;
    }
}
@media only screen and (min-width: 506px) and (max-width: 768px) {
    .cards .leadate {
        margin-right: 10px;
    }
    .cads .card .usnam1 label:nth-child(1) {
        font-size: 12px;
        width: 80px;
    }
    .cads .card .usnam label:nth-child(1) {
        font-size: 12px;
        width: 120px;
    }
    .cads .card .usnam1 label:nth-child(2) {
        font-size: 12px;
    }
    .cads .card .usnam label:nth-child(2) {
        font-size: 12px;
    }
    .cards .card {
        padding: 10px 15px;
    }
    .cards {
        padding: 15px 15px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
    .center {
        width: 275px;
        padding: 80px 25px 45px 25px;
    }
    .dropdown {
        width: 240px;
        margin-top: 10px;
        padding: 10px;
    }
    .UserInfo {
        width: 230px;
    }
    .UserInfo .usnam .usnam1 label:nth-child(1) {
        width: 60px;
    }
    .usnamebtn label {
        font-size: 10px;
        line-height: 28px;
        padding: 0 5px;
    }
    .usnamebtn label img {
        padding-right: 5px;
    }
    #BulkUploadModal {
        padding: 25px;
    }
    #BulkUploadModal .addnote {
        background-color: #ffffff;
        border-radius: 8px;
        height: 325px;
        width: 190px;
    }
    #BulkUploadModal .mainfileUpload {
        height: 190px;
    }
    .file-icon {
        padding: 15px 0 !important;
        margin-bottom: 10px !important;
    }
    #BulkUploadModal .fileupload {
        height: 28px;
        padding: 0 10px;
        font-size: 12px;
        margin: 12px 0;
    }
    #BulkUploadModal .editorbtn {
        float: none;
        display: flex;
    }
    #BulkUploadModal .editorbtn button:nth-child(1) {
        margin-right: 15px;
    }
    #BulkUploadModal .editorbtn button {
        padding: 10px 25px;
    }
    #BulkUploadModal label:nth-child(1) {
        font-size: 12px;
    }
    #BulkUploadModal label:nth-child(2) {
        font-size: 12px;
        padding: 4px;
    }
}
