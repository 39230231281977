@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "./../../../node_modules//bootstrap/dist/css/bootstrap.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
/* Change the input to background color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #ECF2F4 inset !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
label {
    margin: 0;
}
.Hiderightside .leaDetLeft {
    width: 30%;
}
.Hiderightside .leaDetRight {
    width: 70%;
}
.Hiderightside .lefSide .Quote {
    width: 435px;
}
.Hiderightside .Calll .lefSide .Quote {
    width: 150px;
}
.Hiderightside .HistoryMain ul li {
    margin-bottom: 50px;
}