@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./../../../../node_modules/bootstrap//dist//css//bootstrap.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
.HierarchyMain,
.TaskTypeMain,
.PriorityMain,
.SplMasterMain,
.LeadStageMain,
.CrmRoleMain,
.CategryMain,
.AlertsMain,
.CustomFieldsMain,
.LocationMain,
.UsersMain,
.NotificationMain,
.DndMain,
.SlaMasterMain,
.LeadScorCusMain {
  padding: 15px 20px;
  background-color: rgb(242, 244, 247);
  height: 100vh;
}
.roleaccessMain {
  padding: 15px 20px;
  background-color: rgb(242, 244, 247);
}
/* Lead,TaskType Header */
.Hierdf {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 15px;
}
.Hierdf .hierleft h4 {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
}
.Hierdf .hierleft h4 img {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.hierdown label img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.hierdown label {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 13px;
  line-height: 25px;
  cursor: pointer;
  margin-right: 20px;
  font-weight: 600;
}
.hierright .ant-input-affix-wrapper {
  background-color: #f9f9f9;
  border: 0.5px solid #eaeaea;
  border-radius: 1.5px;
  padding: 6px 10px;
}
.sear .ant-input-affix-wrapper {
  background-color: #fff;
  border: 0.5px solid #eaeaea;
  border-radius: 1.5px;
  padding: 8px 10px;
}
.hierright .ant-input-affix-wrapper > input.ant-input {
  background-color: #f9f9f9;
}
.sear .ant-input-affix-wrapper > input.ant-input {
  background-color: #fff;
}
.hierright .ant-input-affix-wrapper:focus,
.hierright .ant-input-affix-wrapper-focused,
.sear .ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}
/* Hierarchy,TaskType Table */
.HierarchyTable,
.TaskTypeTable,
.LeadStageTable,
.PriorityTable,
.SplMasterTable,
.CrmRolesTable,
.CategiryTable,
.CustomFieldsTable,
.AlertTable,
.LocationTable,
.UsersTable,
.NotificationTable,
.DndTable,
.RoleAccessTable,
.SlaMasterTable,
.LeadScorCusTable {
  margin: 20px 0;
}
.HierarchyTable .ant-table-tbody > tr > td:nth-child(2),
.TaskTypeTable .ant-table-tbody > tr > td:nth-child(2),
.PriorityTable .ant-table-tbody > tr > td:nth-child(2),
.SplMasterTable .ant-table-tbody > tr > td:nth-child(2),
.LeadStageTable .ant-table-tbody > tr > td:nth-child(2),
.CrmRolesTable .ant-table-tbody > tr > td:nth-child(2),
.LocationTable .ant-table-tbody > tr > td:nth-child(2),
.UsersTable .ant-table-tbody > tr > td:nth-child(2),
.NotificationTable .ant-table-tbody > tr > td:nth-child(2),
.DndTable .ant-table-tbody > tr > td:nth-child(2),
.SlaMasterTable .ant-table-tbody > tr > td:nth-child(2),
.LeadScorCusTable .ant-table-tbody > tr > td:nth-child(2),
.CustomFieldsTable .ant-table-tbody > tr > td:nth-child(2),
.AlertTable .ant-table-tbody > tr > td:nth-child(2),
.RoleAccessTable .ant-table-tbody > tr > td:nth-child(2),
.CategiryTable .ant-table-tbody > tr > td:nth-child(2) {
  padding: 0;
}
.HierarchyTable .ant-table,
.TaskTypeTable .ant-table,
.PriorityTable .ant-table,
.SplMasterTable .ant-table,
.CrmRolesTable .ant-table,
.LeadStageTable .ant-table,
.LocationTable .ant-table,
.UsersTable .ant-table,
.NotificationTable .ant-table,
.DndTable .ant-table,
.SlaMasterTable .ant-table,
.LeadScorCusTable .ant-table,
.CustomFieldsTable .ant-table,
.AlertTable .ant-table,
.RoleAccessTable .ant-table,
.CategiryTable .ant-table {
  background-color: #e5e9ed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.HierarchyTable .ant-table-tbody,
.TaskTypeTable .ant-table-tbody,
.PriorityTable .ant-table-tbody,
.SplMasterTable .ant-table-tbody,
.RoleAccessTable .ant-table-tbody,
.CrmRolesTable .ant-table-tbody,
.LeadStageTable .ant-table-tbody,
.LocationTable .ant-table-tbody,
.UsersTable .ant-table-tbody,
.NotificationTable .ant-table-tbody,
.DndTable .ant-table-tbody,
.SlaMasterTable .ant-table-tbody,
.LeadScorCusTable .ant-table-tbody,
.CustomFieldsTable .ant-table-tbody,
.AlertTable .ant-table-tbody,
.CategiryTable .ant-table-tbody {
  background: #fff;
}
.HierarchyTable .ant-table-thead > tr > th,
.RoleAccessTable .ant-table-thead > tr > th,
.TaskTypeTable .ant-table-thead > tr > th,
.PriorityTable .ant-table-thead > tr > th,
.SplMasterTable .ant-table-thead > tr > th,
.CrmRolesTable .ant-table-thead > tr > th,
.LeadStageTable .ant-table-thead > tr > th,
.LocationTable .ant-table-thead > tr > th,
.UsersTable .ant-table-thead > tr > th,
.NotificationTable .ant-table-thead > tr > th,
.DndTable .ant-table-thead > tr > th,
.SlaMasterTable .ant-table-thead > tr > th,
.LeadScorCusTable .ant-table-thead > tr > th,
.CustomFieldsTable .ant-table-thead > tr > th,
.AlertTable .ant-table-thead > tr > th,
.CategiryTable .ant-table-thead > tr > th {
  background-color: #e5e9ed;
  font-weight: 600;
  height: 50px;
  /* padding: 18px 15px; */
  color: #4a4a4a;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
.HierarchyTable .ant-table-selection-column,
.TaskTypeTable .ant-table-selection-column,
.PriorityTable .ant-table-selection-column,
.SplMasterTable .ant-table-selection-column,
.CrmRolesTable .ant-table-selection-column,
.LocationTable .ant-table-selection-column,
.UsersTable .ant-table-selection-column,
.LeadStageTable .ant-table-selection-column,
.NotificationTable .ant-table-selection-column,
.DndTable .ant-table-selection-column,
.SlaMasterTable .ant-table-selection-column,
.LeadScorCusTable .ant-table-selection-column,
.CustomFieldsTable .ant-table-selection-column,
.RoleAccessTable .ant-table-selection-column,
.AlertTable .ant-table-selection-column,
.CategiryTable .ant-table-selection-column {
  padding: 0 25px !important;
}
.HierarchyTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.TaskTypeTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.RoleAccessTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.PriorityTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.SplMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.CrmRolesTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.LocationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.UsersTable .ant-table-container table > thead > tr:first-child th:first-child,
.NotificationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.DndTable .ant-table-container table > thead > tr:first-child th:first-child,
.LeadStageTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.CustomFieldsTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.CategiryTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.AlertTable .ant-table-container table > thead > tr:first-child th:first-child,
.SlaMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child,
.LeadScorCusTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 8px;
}
.HierarchyTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.TaskTypeTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.RoleAccessTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.PriorityTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.SplMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.CrmRolesTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.LocationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.UsersTable .ant-table-container table > thead > tr:first-child th:last-child,
.NotificationTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.DndTable .ant-table-container table > thead > tr:first-child th:last-child,
.LeadStageTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.CustomFieldsTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.CategiryTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.AlertTable .ant-table-container table > thead > tr:first-child th:last-child,
.SlaMasterTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child,
.LeadScorCusTable
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 8px;
}
.HierarchyTable .ant-table-tbody > tr > td,
.TaskTypeTable .ant-table-tbody > tr > td,
.PriorityTable .ant-table-tbody > tr > td,
.SplMasterTable .ant-table-tbody > tr > td,
.CrmRolesTable .ant-table-tbody > tr > td,
.LocationTable .ant-table-tbody > tr > td,
.UsersTable .ant-table-tbody > tr > td,
.NotificationTable .ant-table-tbody > tr > td,
.DndTable .ant-table-tbody > tr > td,
.LeadStageTable .ant-table-tbody > tr > td,
.SlaMasterTable .ant-table-tbody > tr > td,
.LeadScorCusTable .ant-table-tbody > tr > td,
.CustomFieldsTable .ant-table-tbody > tr > td,
.RoleAccessTable .ant-table-tbody > tr > td,
.AlertTable .ant-table-tbody > tr > td,
.CategiryTable .ant-table-tbody > tr > td {
  border-bottom: 1px solid #eaeaea;
}
.HierarchyTable .ant-table-tbody > tr.ant-table-row:hover > td,
.TaskTypeTable .ant-table-tbody > tr.ant-table-row:hover > td,
.RoleAccessTable .ant-table-tbody > tr.ant-table-row:hover > td,
.PriorityTable .ant-table-tbody > tr.ant-table-row:hover > td,
.SplMasterTable .ant-table-tbody > tr.ant-table-row:hover > td,
.AlertTable .ant-table-tbody > tr.ant-table-row:hover > td,
.CategiryTable .ant-table-tbody > tr.ant-table-row:hover > td,
.CustomFieldsTable .ant-table-tbody > tr.ant-table-row:hover > td,
.LocationTable .ant-table-tbody > tr.ant-table-row:hover > td,
.UsersTable .ant-table-tbody > tr.ant-table-row:hover > td,
.LeadStageTable .ant-table-tbody > tr.ant-table-row:hover > td,
.NotificationTable .ant-table-tbody > tr.ant-table-row:hover > td,
.DndTable .ant-table-tbody > tr.ant-table-row:hover > td,
.SlaMasterTable .ant-table-tbody > tr.ant-table-row:hover > td,
.LeadScorCusTable .ant-table-tbody > tr.ant-table-row:hover > td,
.CrmRolesTable .ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}
.HierarchyTable .report label,
.RoleAccessTable .report label,
.TaskTypeTable .report label,
.LeadStageTable .report label,
.PriorityTable .report label,
.SplMasterTable .report label,
.AlertTable .report label,
.CategiryTable .report label,
.CustomFieldsTable .report label,
.LocationTable .report label,
.UsersTable .report label,
.NotificationTable .report label,
.DndTable .report label,
.SlaMasterTable .report label,
.LeadScorCusTable .report label,
.CrmRolesTable .report label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Lato;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
}
.HierarchyTable .Actionic img,
.RoleAccessTable .Actionic img,
.TaskTypeTable .Actionic img,
.LeadStageTable .Actionic img,
.PriorityTable .Actionic img,
.SplMasterTable .Actionic img,
.CategiryTable .Actionic img,
.CustomFieldsTable .Actionic img,
.LocationTable .Actionic img,
.AlertTable .Actionic img,
.UsersTable .Actionic img,
.NotificationTable .Actionic img,
.DndTable .Actionic img,
.SlaMasterTable .Actionic img,
.LeadScorCusTable .Actionic img,
.CrmRolesTable .Actionic img {
  width: 15px;
  height: 15px;
  cursor: pointer;
  vertical-align: initial;
  margin-right: 15px;
}
.HierarchyTable .Status .ant-select-selector,
.RoleAccessTable .Status .ant-select-selector,
.TaskTypeTable .Status .ant-select-selector,
.LeadStageTable .Status .ant-select-selector,
.PriorityTable .Status .ant-select-selector,
.SplMasterTable .Status .ant-select-selector,
.CategiryTable .Status .ant-select-selector,
.CustomFieldsTable .Status .ant-select-selector,
.LocationTable .Status .ant-select-selector,
.AlertTable .Status .ant-select-selector,
.UsersTable .Status .ant-select-selector,
.NotificationTable .Status .ant-select-selector,
.DndTable .Status .ant-select-selector,
.SlaMasterTable .Status .ant-select-selector,
.LeadScorCusTable .Status .ant-select-selector,
.CrmRolesTable .Status .ant-select-selector {
  background-color: #f2f8f8 !important;
  border-radius: 10px !important;
  border: 0 !important;
  color: #07a287;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
  height: 25px !important;
  font-weight: 600;
}
.HierarchyTable .Status .ant-select-arrow,
.TaskTypeTable .Status .ant-select-arrow,
.PriorityTable .Status .ant-select-arrow,
.SplMasterTable .Status .ant-select-arrow,
.CategiryTable .Status .ant-select-arrow,
.CustomFieldsTable .Status .ant-select-arrow,
.AlertTable .Status .ant-select-arrow,
.LocationTable .Status .ant-select-arrow,
.UsersTable .Status .ant-select-arrow,
.LeadStageTable .Status .ant-select-arrow,
.NotificationTable .Status .ant-select-arrow,
.DndTable .Status .ant-select-arrow,
.SlaMasterTable .Status .ant-select-arrow,
.LeadScorCusTable .Status .ant-select-arrow,
.CrmRolesTable .Status .ant-select-arrow {
  color: #07a287;
  font-size: 10px;
}
.HierarchyTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.TaskTypeTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.PriorityTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SplMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CategiryTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.AlertTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CustomFieldsTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LocationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.UsersTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.NotificationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadStageTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.DndTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CrmRolesTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SlaMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadScorCusTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.HierarchyTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.TaskTypeTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.PriorityTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SplMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CategiryTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.AlertTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CustomFieldsTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LocationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.UsersTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.NotificationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadStageTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.DndTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CrmRolesTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SlaMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadScorCusTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 26px !important;
  color: #07a287;
  font-size: 10px;
}
.HierarchyTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.TaskTypeTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.PriorityTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SplMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CategiryTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.AlertTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CustomFieldsTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LocationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.UsersTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadStageTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.NotificationTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.CrmRolesTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.SlaMasterTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.LeadScorCusTable
  .Status
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.HierarchyTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.TaskTypeTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.PriorityTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SplMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CategiryTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.AlertTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CustomFieldsTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LocationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.UsersTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.NotificationTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadStageTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.CrmRolesTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.SlaMasterTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.LeadScorCusTable
  .Status
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 26px !important;
}
/* Create Hierarchy,Create Task Modal */
#CreateHierarchyModal .react-responsive-modal-closeButton,
#CreateTaskTyModal .react-responsive-modal-closeButton,
#CreatePriorityModal .react-responsive-modal-closeButton,
#CreateSplMasterModal .react-responsive-modal-closeButton,
#CreateCrmRoleModal .react-responsive-modal-closeButton,
#CreateCategoryModal .react-responsive-modal-closeButton,
#CreateAlertModal .react-responsive-modal-closeButton,
#CreateLocationModal .react-responsive-modal-closeButton,
#CreateCustomFieldsModal .react-responsive-modal-closeButton,
#CreateUserModal .react-responsive-modal-closeButton,
#CreateNotificationModal .react-responsive-modal-closeButton,
#CreateDndModal .react-responsive-modal-closeButton,
#CreateLeadStagManaModal .react-responsive-modal-closeButton,
#CreateSlaMasterModal .react-responsive-modal-closeButton,
#SelectTempModal .react-responsive-modal-closeButton,
#CreateLeadScoModal .react-responsive-modal-closeButton,
#CreateAlertTempModal .react-responsive-modal-closeButton {
  display: none;
}
#CreateHierarchyModal,
#CreateTaskTyModal,
#CreatePriorityModal,
#CreateSplMasterModal,
#CreateCrmRoleModal,
#CreateCategoryModal,
#CreateAlertModal,
#CreateLocationModal,
#CreateCustomFieldsModal,
#CreateUserModal,
#CreateNotificationModal,
#CreateDndModal,
#CreateLeadStagManaModal,
#CreateSlaMasterModal,
#CreateLeadScoModal,
#SelectTempModal,
#CreateAlertTempModal {
  background-color: #fff;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
#CreateHierarchyModal .createHier,
#CreatePriorityModal .createPrior,
#CreateSplMasterModal .createSplMas,
#CreateCrmRoleModal .createCrmRole,
#CreateCategoryModal .createCategory,
#CreateAlertModal .createAlerts,
#CreateNotificationModal .createNoti,
#CreateLeadStagManaModal .createLeadStag {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
#CreateDndModal .createDnd{
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 715px;
  border-bottom: 1px solid #dedede;
}
#CreateAlertTempModal .createAlerts1 {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
#CreateUserModal .createUser {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 650px;
  height: 450px;
  border-bottom: 1px solid #dedede;
}
#CreateLocationModal .createLocation {
  position: relative;
}
#CreateCustomFieldsModal .createCustomFields,
#CreateLocationModal .createLocation,
#CreateAlertModal .createAlerts {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
#CreateSlaMasterModal .createSlaMa,
#CreateLeadScoModal .createLeaScoCus {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 650px;
  border-bottom: 1px solid #dedede;
}
#CreateTaskTyModal .createTas {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
  border-bottom: 1px solid #dedede;
}
.createHier .hier label,
.createTas .hier label,
.createPrior .hier label,
.createSplMas .hier label,
.createCrmRole .hier label,
.createCategory .hier label,
.createLocation .hier label,
.createCustomFields .hier label,
.createDnd .hier label,
.createNoti .hier label,
.createLeadStag .hier label,
.createLeaScoCus .hier label,
.createSlaMa .hier label,
.createAlerts .hier label {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
}
.createAlerts .hier1 label {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
}
.createHier .desig,
.createTas .desig,
.createPrior .desig,
.createSplMas .desig,
.createCrmRole .desig,
.createCategory .desig,
.createLocation .desig,
.createCustomFields .desig,
.createDnd .desig,
.createNoti .desig,
.createLeadStag .desig,
.createSlaMa .desig,
.createLeaScoCus .desig,
.createUser .desig {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  display: block;
}
.createSlaMa .dndChe {
  float: right;
}
.createSlaMa .dndChe span {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
}
.createHier .single {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
}
.createUser .ant-picker,
.createTas .ant-picker {
  padding: 8px;
  background-color: #f9f9f9;
}
.createHier input:focus,
.createTas input:focus,
.createTas textarea:focus,
.createPrior input:focus,
.createSplMas input:focus,
.createCrmRole input:focus,
.createCategory input:focus,
.createNoti input:focus,
.createDnd input:focus,
.createUser input:focus,
.createNoti textarea:focus,
.createLocation input:focus,
.createAlerts input:focus,
.createCustomFields input:focus,
.createLocation textarea:focus,
.createLeadStag input:focus,
.createSlaMa input:focus,
.createLeaScoCus input:focus {
  outline: 0;
}
.createHier input,
.createTas input,
.createTas textarea,
.createPrior input,
.createSplMas input,
.createCrmRole input,
.createCategory input,
.createNoti input,
.createUser input,
.createLocation input,
.createAlerts input,
.createCustomFields input,
.createLocation textarea,
.createDnd input,
.createLeadStag input,
.createSlaMa input,
.createLeaScoCus input,
.createNoti textarea {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 8px;
  background: #f9f9f9;
}
.createHier .ant-select,
.createTas .ant-select,
.createPrior .ant-select,
.createCrmRole .ant-select,
.createCategory .ant-select,
.createNoti .ant-select,
.createDnd .ant-select,
.createUser .ant-select,
.createLeadStag .ant-select,
.createLocation .ant-select,
.createLeaScoCus .ant-select,
.createAlerts .ant-select,
.createCustomFields .ant-select,
.createSlaMa .ant-select,
.hierdown1 .ant-select {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 40px;
  background: #f9f9f9;
}
.sear .ant-select {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 40px;
  background: #fff;
}
.createHier .ant-select-single .ant-select-selector .ant-select-selection-item,
.createTas .ant-select-single .ant-select-selector .ant-select-selection-item,
.createLeadStag
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createPrior .ant-select-single .ant-select-selector .ant-select-selection-item,
.createCategory
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createCrmRole
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createNoti .ant-select-single .ant-select-selector .ant-select-selection-item,
.createDnd .ant-select-single .ant-select-selector .ant-select-selection-item,
.createUser .ant-select-single .ant-select-selector .ant-select-selection-item,
.createLocation
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createAlerts
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.createSlaMa .ant-select-single .ant-select-selector .ant-select-selection-item,
.createLeaScoCus
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.sear .ant-select-single .ant-select-selector .ant-select-selection-item,
.hierdown1 .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}
.createHier
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createTas
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createPrior
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createCategory
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createUser
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createLocation
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createAlerts
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createNoti
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createDnd
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createLeadStag
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createCrmRole
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createSlaMa
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.createLeaScoCus
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.sear
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.hierdown1
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.createHier .ant-select-selector,
.createTas .ant-select-selector,
.createPrior .ant-select-selector,
.createCategory .ant-select-selector,
.createUser .ant-select-selector,
.createLocation .ant-select-selector,
.createAlerts .ant-select-selector,
.createCustomFields .ant-select-selector,
.createNoti .ant-select-selector,
.createDnd .ant-select-selector,
.createLeadStag .ant-select-selector,
.createCrmRole .ant-select-selector,
.createLeaScoCus .ant-select-selector,
.createSlaMa .ant-select-selector,
.hierdown1 .ant-select-selector {
  border: 0 !important;
  background-color: #f9f9f9 !important;
}
.sear .ant-select-selector {
  border: 0 !important;
  background-color: #fff !important;
}
.createCrmRole .fullacces {
  color: #007aff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  font-family: lato;
  padding-top: 10px;
}
#CreateHierarchyModal .editorbtn,
#CreateTaskTyModal .editorbtn,
#CreatePriorityModal .editorbtn,
#CreateSplMasterModal .editorbtn,
#CreateCrmRoleModal .editorbtn,
#CreateCategoryModal .editorbtn,
#CreateAlertModal .editorbtn,
#CreateNotificationModal .editorbtn,
#CreateDndModal .editorbtn,
#CreateLeadStagManaModal .editorbtn,
#CreateUserModal .editorbtn,
#CreateCustomFieldsModal .editorbtn,
#CreateLocationModal .editorbtn,
#CreateLeadScoModal .editorbtn,
#CreateSlaMasterModal .editorbtn {
  float: right;
}
#CreateHierarchyModal .editorbtn button:focus,
#CreateTaskTyModal .editorbtn button:focus,
#CreatePriorityModal .editorbtn button:focus,
#CreateSplMasterModal .editorbtn button:focus,
#CreateCategoryModal .editorbtn button:focus,
#CreateAlertModal .editorbtn button:focus,
#CreateCrmRoleModal .editorbtn button:focus,
#CreateNotificationModal .editorbtn button:focus,
#CreateDndModal .editorbtn button:focus,
#CreateLeadStagManaModal .editorbtn button:focus,
#CreateUserModal .editorbtn button:focus,
#CreateCustomFieldsModal .editorbtn button:focus,
#CreateLocationModal .editorbtn button:focus,
#CreateLeadScoModal .editorbtn button:focus,
#CreateSlaMasterModal .editorbtn button:focus {
  outline: 0;
}
#CreateHierarchyModal .editorbtn button,
#CreateTaskTyModal .editorbtn button,
#CreatePriorityModal .editorbtn button,
#CreateSplMasterModal .editorbtn button,
#CreateCategoryModal .editorbtn button,
#CreateAlertModal .editorbtn button,
#CreateCrmRoleModal .editorbtn button,
#CreateNotificationModal .editorbtn button,
#CreateDndModal .editorbtn button,
#CreateLeadStagManaModal .editorbtn button,
#CreateUserModal .editorbtn button,
#CreateCustomFieldsModal .editorbtn button,
#CreateLocationModal .editorbtn button,
#CreateLeadScoModal .editorbtn button,
#CreateSlaMasterModal .editorbtn button {
  border-radius: 4px;
  font-family: Lato;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 40px;
  text-transform: capitalize;
  margin: 20px 0;
}
#CreateHierarchyModal .editorbtn button:nth-child(2),
#CreateTaskTyModal .editorbtn button:nth-child(2),
#CreatePriorityModal .editorbtn button:nth-child(2),
#CreateSplMasterModal .editorbtn button:nth-child(2),
#CreateCategoryModal .editorbtn button:nth-child(2),
#CreateAlertModal .editorbtn button:nth-child(2),
#CreateCrmRoleModal .editorbtn button:nth-child(2),
#CreateNotificationModal .editorbtn button:nth-child(2),
#CreateDndModal .editorbtn button:nth-child(2),
#CreateLeadStagManaModal .editorbtn button:nth-child(2),
#CreateUserModal .editorbtn button:nth-child(2),
#CreateCustomFieldsModal .editorbtn button:nth-child(2),
#CreateLocationModal .editorbtn button:nth-child(2),
#CreateLeadScoModal .editorbtn button:nth-child(2),
#CreateSlaMasterModal .editorbtn button:nth-child(2) {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  margin-right: 15px;
  color: #ffffff;
}
#CreateHierarchyModal .editorbtn button:nth-child(1),
#CreateTaskTyModal .editorbtn button:nth-child(1),
#CreatePriorityModal .editorbtn button:nth-child(1),
#CreateSplMasterModal .editorbtn button:nth-child(1),
#CreateCategoryModal .editorbtn button:nth-child(1),
#CreateAlertModal .editorbtn button:nth-child(1),
#CreateCrmRoleModal .editorbtn button:nth-child(1),
#CreateNotificationModal .editorbtn button:nth-child(1),
#CreateDndModal .editorbtn button:nth-child(1),
#CreateLeadStagManaModal .editorbtn button:nth-child(1),
#CreateUserModal .editorbtn button:nth-child(1),
#CreateLocationModal .editorbtn button:nth-child(1),
#CreateLeadScoModal .editorbtn button:nth-child(1),
#CreateCustomFieldsModal .editorbtn button:nth-child(1),
#CreateSlaMasterModal .editorbtn button:nth-child(1) {
  background-color: transparent;
  border: 1px solid #bcbcbc;
  color: #4a4a4a;
  margin-right: 15px;
}
#CreateDndModal .addnew button {
  border: 1px solid #007aff;
  color: #007aff;
  border-radius: 4px;
  font-family: Lato;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 10px;
  text-transform: capitalize;
  background: transparent;
  font-weight: 600;
}
#CreateLeadStagManaModal .addStatusBtn,
#CreateLeadScoModal .addStatusBtn {
  border: 1px solid #007aff;
  border-radius: 2px;
  width: 100%;
  height: 27px;
  color: #007aff;
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #fff;
  font-weight: 600;
}
#CreateLeadStagManaModal .imgdrag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#CreateLeadStagManaModal .imgdrag img {
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin-top: 5px;
}
.createSlaMa .card {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 10px;
  background: #f9f9f9;
}
.createSlaMa .card .pro {
  font-size: 13px;
  font-weight: 600;
  font-family: lato;
  margin-bottom: 10px;
}
.createSlaMa .card .low {
  font-size: 13px;
  font-weight: 500;
  display: block;
  color: #a7a7a7;
  font-family: Lato;
  line-height: 25px;
  height: 22px;
  margin-bottom: 10px;
}
.createSlaMa .card .high {
  display: flex;
  margin-bottom: 10px;
}
.createSlaMa .card input {
  height: 22px;
}
.createSlaMa .high .ant-select-selector {
  background-color: #fff !important;
}
.createSlaMa .high .ant-select {
  width: 100%;
  border: 0;
  border-radius: 4px;
  height: 22px;
  background: #fff;
  margin-right: 10px;
}
.createSlaMa
  .high
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  line-height: 22px;
}
.createSlaMa
  .high
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 22px;
  border: 1px solid #ededed !important;
}
.createLeaScoCus .hott {
  display: flex;
}
.createLeaScoCus .hott label {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.createLeaScoCus .cold {
  background: #98e5ff;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.createLeaScoCus .warm {
  background: #ffe050;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.createLeaScoCus .hot {
  background: #ff3200;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.createCustomFields .mand {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  display: inline-block;
  margin: 0 10px 8px 0 !important;
}
/* Download and Upload */
#CreateHierarchyModal .drop,
#CreateTaskTyModal .drop,
#CreateCrmRoleModal .drop,
#CreateDndModal .drop,
#CreateCategoryModal .drop,
#CreateAlertModal .drop,
#CreateUserModal .drop,
#CreateSlaMasterModal .drop,
#CreateCustomFieldsModal .drop,
#CreateLocationModal .drop,
#CreateCrmRoleModal .drop {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#CreateHierarchyModal .down,
#CreateTaskTyModal .down,
#CreateCrmRoleModal .down,
#CreateDndModal .down,
#CreateCategoryModal .down,
#CreateAlertModal .down,
#CreateUserModal .down,
#CreateSlaMasterModal .down,
#CreateCustomFieldsModal .down,
#CreateLocationModal .down,
#CreateCrmRoleModal .down {
  display: block;
  color: #2187fd;
  font-family: Lato;
  font-size: 12px;
  line-height: 14px;
}
#CreateHierarchyModal .mainfileUpload div:focus,
#CreateTaskTyModal .mainfileUpload div:focus,
#CreateCrmRoleModal .mainfileUpload div:focus,
#CreateDndModal .mainfileUpload div:focus,
#CreateCategoryModal .mainfileUpload div:focus,
#CreateAlertModal .mainfileUpload div:focus,
#CreateUserModal .mainfileUpload div:focus,
#CreateSlaMasterModal .mainfileUpload div:focus,
#CreateCustomFieldsModal .mainfileUpload div:focus,
#CreateLocationModal .mainfileUpload div:focus,
#CreateCrmRoleModal .mainfileUpload div:focus {
  outline: 0;
}
#CreateHierarchyModal .mainfileUpload,
#CreateTaskTyModal .mainfileUpload,
#CreateDndModal .mainfileUpload,
#CreateTaskTyModal .mainfileUpload,
#CreateCategoryModal .mainfileUpload,
#CreateAlertModal .mainfileUpload,
#CreateUserModal .mainfileUpload,
#CreateSlaMasterModal .mainfileUpload,
#CreateCustomFieldsModal .mainfileUpload,
#CreateLocationModal .mainfileUpload,
#CreateCrmRoleModal .mainfileUpload {
  width: 100%;
  height: 250px;
  border: 3px dotted #1484ff;
  border-radius: 8px;
  margin: 15px 0;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9edf3;
}
#CreateHierarchyModal .file-icon img,
#CreateTaskTyModal .file-icon img,
#CreateCrmRoleModal .file-icon img,
#CreateDndModal .file-icon img,
#CreateCategoryModal .file-icon img,
#CreateAlertModal .file-icon img,
#CreateUserModal .file-icon img,
#CreateSlaMasterModal .file-icon img,
#CreateCustomFieldsModal .file-icon img,
#CreateLocationModal .file-icon img,
#CreateCrmRoleModal .file-icon img {
  width: 30px;
  height: 30px;
}
#CreateHierarchyModal .file-icon,
#CreateTaskTyModal .file-icon,
#CreateCrmRoleModal .file-icon,
#CreateDndModal .file-icon,
#CreateCategoryModal .file-icon,
#CreateAlertModal .file-icon,
#CreateUserModal .file-icon,
#CreateSlaMasterModal .file-icon,
#CreateCustomFieldsModal .file-icon,
#CreateLocationModal .file-icon,
#CreateCrmRoleModal .file-icon {
  background: #dde3ec;
  padding: 25px 0;
  border-radius: 6px;
  margin-bottom: 20px;
}
#CreateHierarchyModal .fileupload,
#CreateTaskTyModal .fileupload,
#CreateCrmRoleModal .fileupload,
#CreateDndModal .fileupload,
#CreateCategoryModal .fileupload,
#CreateAlertModal .fileupload,
#CreateUserModal .fileupload,
#CreateSlaMasterModal .fileupload,
#CreateCustomFieldsModal .fileupload,
#CreateLocationModal .fileupload,
#CreateCrmRoleModal .fileupload {
  background-color: #2187fd;
  border: 1px solid #2187fd;
  border-radius: 4px;
  height: 38px;
  padding: 0 10px;
  cursor: pointer;
  color: #ffffff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin: 20px 0;
}
/* Step Progress Bar */
#CreateLocationModal .createLocation .previousBtn {
  border: 1px solid #f9f9f9;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  background: #f9f9f9;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  top: 30px;
}
#CreateUserModal .createUser .ant-steps-label-vertical .ant-steps-item-content,
#CreateCustomFieldsModal
  .createCustomFields
  .ant-steps-label-vertical
  .ant-steps-item-content,
#CreateLocationModal
  .createLocation
  .ant-steps-label-vertical
  .ant-steps-item-content {
  margin-top: -30px !important;
}
#CreateUserModal .createUser .ant-steps-item-title,
#CreateCustomFieldsModal .createCustomFields .ant-steps-item-title,
#CreateLocationModal .createLocation .ant-steps-item-title {
  font-size: 12px;
  color: #4a4a4a;
  line-height: 12px;
  font-family: lato;
}
#CreateUserModal .createUser .Steep {
  margin: 30px auto;
  width: 100%;
}
#CreateLocationModal .createLocation .Steep {
  margin: 30px auto;
  width: 80%;
}
#CreateLocationModal
  .createLocation
  .ant-steps-item-finish
  .ant-steps-item-title,
#CreateUserModal .createUser .ant-steps-item-finish .ant-steps-item-title,
#CreateLocationModal
  .createLocation
  .ant-steps-item-active
  .ant-steps-item-title {
  color: #1484ff !important;
}
#CreateUserModal .createUser .ant-steps-dot .ant-steps-item-tail::after,
#CreateLocationModal
  .createLocation
  .ant-steps-dot
  .ant-steps-item-tail::after {
  width: calc(100% - 8px);
  height: 3px;
  margin-left: 5px;
}
.createAlerts1 .hier label {
  font-size: 16px;
  color: #4A4A4A;
  font-weight: 600;
  font-family: lato;
}
.createAlerts1 .hier label span {
  font-weight: 500;
}
.createAlerts1 .ant-tabs-nav-wrap {
  background: #EDEDED;
  border-radius: 6px;
  justify-content: center;
}
.createAlerts1 .ant-tabs-tab {
  margin: 0;
  padding: 12px;
  cursor: pointer;
  font-weight: 600;
  color: #4A4A4A;
}
.createAlerts1 .ant-tabs-tab label {
  cursor: pointer;
}
.createAlerts1 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
}
.createAlerts1 .Ema .Compose {
  font-size: 16px;
  font-weight: 500;
  color: #4A4A4A;
  line-height: 20px;
}
.createAlerts1 .Ema .Card {
  border: 1px solid #EDEDED;
  width: 100%;
  height: 200px;
  margin: 15px 0;
  border-radius: 6px;
  padding: 10px;
}
.createAlerts1 .Ema .SavBtn button {
  background: #1484ff;
  color: #fff;
  border-radius: 6px;
  padding: 6px 25px;
  font-size: 16px;
  font-weight: 500;
  border: 0;
  float: right;
}
/* Template Screen CSS */
.TemplateMain {
  padding: 0;
  background-color: rgb(242, 244, 247);
}
.TabTemp .ant-tabs-nav {
  background: #f9f9f9;
  margin-bottom: 40px !important;
}
.TabTemp .ant-tabs-nav-list {
  margin-left: 20px;
}
.TabTemp .ant-tabs-ink-bar {
  display: none;
}
.TabTemp .ant-tabs-tab label {
  cursor: pointer;
}
.TabTemp .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #007aff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  cursor: pointer;
}
.TabTemp .ant-tabs-tab-btn {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
}
.SaveExit {
  position: absolute;
  right: 15px;
  top: 69px;
}
.SaveExit button {
  font-size: 14px;
  font-weight: 500;
  font-family: lato;
  padding: 6px 20px;
  line-height: 16px;
  border-radius: 4px;
  cursor: pointer;
}
.SaveExit button:nth-child(1) {
  background: transparent;
  border: 1px solid #979797;
  color: #979797;
  margin-right: 10px;
}
.SaveExit button:nth-child(2) {
  background: #007aff;
  border: 1px solid #007aff;
  color: #fff;
}
.configure {
  background: #fff;
  width: 80%;
  margin: 0 auto 20px;
  padding: 20px 50px;
  border-radius: 6px;
}
.configure .creat {
  color: #4a4a4a;
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 15px;
}
.configure .desig {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 12px;
  line-height: 24px;
  display: block;
}
.configure .desig1 {
  visibility: hidden;
}
.configure .desig2 {
  text-align: left;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #007aff;
  cursor: pointer;
}
.configure .desig3 {
  text-align: left;
  display: flex;
  height: 40px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #7b7b7b;
}
.configure .desig3 span {
  margin-left: 10px;
}
.configure input:focus {
  outline: 0;
}
.configure input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 8px;
  background: #f9f9f9;
}
.configure .ant-select {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 4px;
  height: 40px;
  background: #f9f9f9;
}
.configure .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 40px;
}
.configure
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}
.configure .ant-select-selector {
  border: 0 !important;
  background-color: #f9f9f9 !important;
}
.configure .Smile {
  float: right;
  width: 15px;
  height: 15px;
  margin-top: 5px;
}
.configure .switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 25px;
  margin-left: 30px;
}
.configure .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.configure .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.configure .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.configure input:checked + .slider {
  background-color: #2196f3;
}
.configure input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.configure input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.configure .slider.round {
  border-radius: 34px;
}
.configure .slider.round:before {
  border-radius: 50%;
}
/* Style Tab */
.style .ant-tabs-nav {
  background: #fff;
  margin: 0 auto 25px !important;
  border-radius: 5px !important;
}
.style .ant-tabs-tab-btn {
  color: #4a4a4a;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 10px;
}
.style .ant-tabs-tab {
  margin-right: 15px;
}
.style .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: #007aff;
  font-family: Roboto;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  padding: 8px 10px;
  border-radius: 4px;
}
.Theme {
  background: #f9f9f9;
  width: 95%;
  margin: 0 auto 20px;
  padding: 20px 50px;
  border-radius: 6px;
}
.Theme .card {
  background-color: #ffffff;
  border-radius: 5px;
  border: 0px solid #ededed;
  padding: 20px;
  cursor: pointer;
}
.Theme .card .text {
  color: #4a4a4a;
  font-family: lato;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  margin-top: 22px;
}
.Theme .card:hover .conthov {
  background-color: #000000;
  opacity: 0.422374636627907;
}
.Theme .card .conthov {
  transition: 0.5s;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 245px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Theme .card .conthov button:nth-child(1) {
  color: #939393;
  background: #fff;
  border: 1px solid #939393;
  border-radius: 4px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;
}
.Theme .card .conthov button:nth-child(2) {
  color: #fff;
  background: #007aff;
  border: 1px solid #007aff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
}
.Theme .card .im {
  width: 100%;
  height: 170px;
  background: #f3f3f3;
}
#SelectTempModal .createTempl {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  width: 600px;
}
.createTempl label {
  color: #9b9b9b;
  font-family: Roboto;
  font-size: 16px;
  line-height: 34px;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.createTempl .threebtn {
  float: right;
  margin-top: 30px;
}
.createTempl .threebtn button:nth-child(1) {
  background: transparent;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  margin-right: 10px;
}
.createTempl .threebtn button:nth-child(2) {
  background: transparent;
  color: #9b9b9b;
  border: 1px solid #9b9b9b;
  margin-right: 10px;
}
.createTempl .threebtn button:nth-child(3) {
  background: #007aff;
  color: #fff;
  border: 1px solid #007aff;
}
.createTempl .threebtn button {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 8px 10px;
  border-radius: 4px;
}
.spanTbl {
  font-size: 9px;
  margin-left: 5px;
  color: #5dabff;
}
.printImg {
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.dndDropDown{
  width: 80px !important;
  height: 30px !important;
}
.dndDropDown span.ant-select-selection-item {
  line-height: 30px !important;
}
.dndDropDown1 .desig {
  display: inline-block !important;
  margin: 0 5px;
}