@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "./../../../../node_modules/bootstrap//dist//css//bootstrap.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
/* Divide Sidebar and Content */
.leftside {
    width: 19%;
    /* height: 100vh; */
    float: left;
    padding-top: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.rightside {
    width: 81%;
    float: right;
    /* height: 100vh; */
    padding-top: 10px;
}
.Hideleftside {
    width: 4%;
}
.Hiderightside {
    width: 96%;
}
.leftside::-webkit-scrollbar
{
    width: 8px;
    cursor: pointer;
}
.leftside::-webkit-scrollbar-thumb
{
	border-radius: 8px;
    background-color: #D2D2D2;
    cursor: pointer;
}
/* Main Header */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
    height: 55px;
    padding: 0 20px;
}
.lefticon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.lefticon img:nth-child(1) {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;
}
.lefticon img:nth-child(2) {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 15px;
    display: none;
}
.lefticon img:nth-child(3) {
    width: 115px;
}
.righticon ul {
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
}
.righticon ul li {
    margin: 0 10px;
}
.righticon ul li img {
    width: 18px;
    height: 18px;
    cursor: pointer;
}
/* Header Logout Modal */
#LogoutModal .react-responsive-modal-closeButton {
    display: none;
}
#LogoutModal {
    background-color: #FFF;
    border-radius: 8px;
    padding: 18px 25px;
    margin: 0;
    position: absolute;
    top: 65px;
    right: 20px;
}
#LogoutModal .logout {
    display: flex;
    align-items: center;
}
#LogoutModal .logprofile {
    display: flex;
    align-items: center;
}
#LogoutModal .logprofile img {
    width: 50px;
    height: 50px;
}
#LogoutModal .logtext {
    padding: 0 30px 0 12px;
}
#LogoutModal .logtext label {
    color: #000000;
    font-family: Lato;
    font-size: 14px;
    line-height: 15px;
    margin: 0;
    display: block;
    font-weight: 600;
    text-transform: capitalize;
}
#LogoutModal .logtext label img {
    width: 13px;
    height: 13px;
    margin: 0 10px;
    cursor: pointer;
}
#LogoutModal .logtext span {
    color: #4B4B4B;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
}
#LogoutModal .logicon a:focus {
    outline: 0;
}
#LogoutModal .logicon img {
    width: 25px;
    height: 25px;
    cursor: pointer;
}
/* Side Menu */
.sidemenu {
    width: 100%;
    /* background: #fff; */
    height: 100vh;
}
.sidemenu ul {
    list-style: none;
    margin: 0;
}
.sidemenu ul li {
    padding: 8px 15px;
    cursor: pointer;
}
.sidemenu ul li img {
    width: 18px;
    height: 18px;
}
.sidemenu ul li:hover,
.sidemenu ul li:focus {
    background: #E2EFFF;
    font-weight: bold;
}
.sidemenu ul li.active {
    background: #E2EFFF;
    font-weight: bold;
}
.sidemenu ul li label {
    margin: 0;
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 14px;
    padding: 0 10px;
    line-height: 18px;
    vertical-align: middle;
    cursor: pointer;
    text-transform: capitalize;
}
.Hideleftside .sidemenu ul li label {
    display: none !important;
}
.Hideleftside .sidemenu ul li .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 15px !important;
}
.Hideleftside .ant-menu-submenu-expand-icon, 
.Hideleftside .ant-menu-submenu-arrow {
    right: 3px;
}
.sidemenu .ant-menu-submenu  {
    padding: 0;
}
.sidemenu .ant-menu-submenu-title {
    padding: 8px 15px !important;
}
.sidemenu .ant-menu {
    border-right: 0 !important;
    padding: 0;
}
.sidemenu ul li .ant-menu:hover {
    background: #E2EFFF;
}
.sidemenu ul li .ant-menu-submenu-inline .ant-menu-submenu-title {
    height: auto;
    line-height: 14px;
}
.sidemenu ul li .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: auto;
    line-height: 40px;
}
.sidemenu .ant-menu-sub.ant-menu-inline {
    background: #fff !important;
}
.sidemenu .ant-menu-item {
    margin: 0 !important;
}
.sidemenu .HierSubMenu {
    font-size: 12px;
}
.sidemenu .ant-menu-inline .ant-menu-submenu-title {
    text-overflow: inherit;
}
.sidemenu .ant-menu-item-only-child {
    padding-left: 35px !important;
}
.Hideleftside .ant-menu-inline .ant-menu-item {
    text-overflow: inherit !important;
}